import React, { Component } from "react"
import logo from "./assets/logo.svg"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Home from "@mui/icons-material/Home"
import DeveloperBoard from "@mui/icons-material/DeveloperBoard"
import AttachMoney from "@mui/icons-material/AttachMoney"
import Settings from "@mui/icons-material/Settings"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import ExitToApp from "@mui/icons-material/ExitToApp"
import { withStyles } from "@mui/styles"
import ClientContext from "./ClientContext"
import { Switch, Route, Link, Redirect } from "react-router-dom"
import { AccountPopover, AboutDialog } from "@igloocloud/igloosharedui"
import { withTranslation } from "react-i18next"

const {
  REACT_APP_MAIN_COLOR: mainColor,
  REACT_APP_TEXT_ON_MAIN_COLOR: textOnMainColor,
} = process.env

const VerticalTabIndicator = ({ path }) => (
  <div
    style={{
      backgroundColor: textOnMainColor,
      height: "48px",
      width: "4px",
      borderRadius: "0 4px 4px 0",
      position: "absolute",
      top: path === "/" ? "8px" : path === "/things" ? "72px" : "136px",
      transition: "top 200ms",
    }}
  />
)

const CustomListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})(ListItem)

export default withTranslation()(
  class Sidebar extends Component {
    constructor(props) {
      super(props)

      this.state = {
        redirect: "",
        aboutOpen: false,
      }
    }

    static contextType = ClientContext

    render() {
      const {
        greaterThan1200,
        openSettings,
        settingsOpen,
        userProps,
        isOpen,
        logOut,
        changeAccount,
        forceUpdate,
        t,
      } = this.props
      const { redirect, aboutOpen } = this.state

      if (redirect) {
        let tempRedirect = redirect
        this.setState({ redirect: "" })

        return (
          <>
            <Redirect push to={tempRedirect} />
            {forceUpdate()}
          </>
        )
      }

      return (
        <div
          style={{ backgroundColor: mainColor, height: "100%" }}
          className="rightShadow"
        >
          <div
            style={{
              height: greaterThan1200
                ? "calc(100% - 192px - env(safe-area-inset-bottom))"
                : "calc(100% - 52px - env(safe-area-inset-bottom))",
            }}
          >
            {greaterThan1200 && (
              <div style={{ display: "flex" }}>
                <img
                  src={logo}
                  style={{
                    height: "32px",
                    margin: "24px 0 24px 24px",
                  }}
                  alt="Igloo logo"
                  className="notSelectable"
                />
                <AccountPopover
                  buttonStyle={{ margin: "20px 20px 0 auto" }}
                  hideExtraButtons
                  setOpen={openSettings}
                  isOpen={settingsOpen}
                  setRedirect={(redirect) => this.setState({ redirect })}
                  user={userProps.data}
                  logOut={logOut}
                  changeAccount={changeAccount}
                />
              </div>
            )}
            <div
              style={{
                overflowY: "auto",
                height: greaterThan1200 ? "calc(100% - 80px)" : "100%",
              }}
            >
              <List
                disablePadding
                style={greaterThan1200 ? {} : { width: "64px" }}
              >
                <Switch>
                  <Route path="/" exact>
                    <VerticalTabIndicator path="/" />
                  </Route>
                  <Route path="/things" exact>
                    <VerticalTabIndicator path="/things" />
                  </Route>
                  {(!localStorage.getItem("server") ||
                    localStorage.getItem("server").includes(".igloo.ooo")) && (
                    <Route path="/payments" exact>
                      <VerticalTabIndicator path="/payments" />
                    </Route>
                  )}
                </Switch>
                <CustomListItem
                  button
                  style={
                    greaterThan1200
                      ? {
                          color: textOnMainColor,
                          paddingLeft: "24px",
                          height: "64px",
                        }
                      : { color: textOnMainColor, height: "64px" }
                  }
                  component={Link}
                  to="/"
                >
                  <ListItemIcon
                    style={{
                      minWidth: greaterThan1200 ? "" : "24px",
                      margin: "auto",
                    }}
                  >
                    <Home style={{ color: textOnMainColor }} />
                  </ListItemIcon>
                  {greaterThan1200 && <ListItemText primary={t`Home`} />}
                </CustomListItem>
                <CustomListItem
                  button
                  style={
                    greaterThan1200
                      ? {
                          color: textOnMainColor,
                          paddingLeft: "24px",
                          height: "64px",
                        }
                      : { color: textOnMainColor, height: "64px" }
                  }
                  component={
                    window.location.pathname === "/things" ? ListItem : Link
                  }
                  to="/things"
                >
                  <ListItemIcon
                    style={{
                      minWidth: greaterThan1200 ? "" : "24px",
                      margin: "auto",
                    }}
                  >
                    <DeveloperBoard style={{ color: textOnMainColor }} />
                  </ListItemIcon>
                  {greaterThan1200 && <ListItemText primary={t`Things`} />}
                </CustomListItem>
                {(!localStorage.getItem("server") ||
                  localStorage.getItem("server").includes(".igloo.ooo")) && (
                  <CustomListItem
                    button
                    style={
                      greaterThan1200
                        ? {
                            color: textOnMainColor,
                            paddingLeft: "24px",
                            height: "64px",
                          }
                        : { color: textOnMainColor, height: "64px" }
                    }
                    component={Link}
                    to="/payments"
                  >
                    <ListItemIcon
                      style={{
                        minWidth: greaterThan1200 ? "" : "24px",
                        margin: "auto",
                      }}
                    >
                      <AttachMoney style={{ color: textOnMainColor }} />
                    </ListItemIcon>
                    {greaterThan1200 && <ListItemText primary={t`Payments`} />}
                  </CustomListItem>
                )}
              </List>
            </div>
          </div>
          {greaterThan1200 ? (
            <List disablePadding>
              <CustomListItem
                button
                style={{
                  color: textOnMainColor,
                  paddingLeft: "24px",
                  height: "64px",
                }}
                onClick={openSettings}
              >
                <ListItemIcon>
                  <Settings style={{ color: textOnMainColor }} />
                </ListItemIcon>
                <ListItemText primary={t`Settings`} />
              </CustomListItem>
              <CustomListItem
                button
                style={{
                  color: textOnMainColor,
                  paddingLeft: "24px",
                  height: "64px",
                }}
                onClick={() => this.setState({ aboutOpen: true })}
              >
                <ListItemIcon>
                  <InfoOutlined style={{ color: textOnMainColor }} />
                </ListItemIcon>
                <ListItemText primary={t("About")} />
              </CustomListItem>
              <CustomListItem
                button
                style={{
                  color: textOnMainColor,
                  paddingLeft: "24px",
                  height: "calc(64px + env(safe-area-inset-bottom))",
                  paddingBottom: "calc(8px + env(safe-area-inset-bottom))",
                }}
                onClick={() => logOut(false)}
              >
                <ListItemIcon>
                  <ExitToApp style={{ color: textOnMainColor }} />
                </ListItemIcon>
                <ListItemText primary={t`Log out`} />
              </CustomListItem>
            </List>
          ) : (
            <AccountPopover
              buttonStyle={{ marginLeft: "12px" }}
              setOpen={this.props.openSettings}
              user={userProps.data}
              setRedirect={(redirect) => this.setState({ redirect })}
              logOut={logOut}
              changeAccount={changeAccount}
              isOpen={isOpen}
            />
          )}
          <AboutDialog
            open={aboutOpen}
            close={() => this.setState({ aboutOpen: false })}
          />
        </div>
      )
    }
  }
)
