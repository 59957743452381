import { cloneElement, isValidElement } from "react";
import JSBI from "jsbi";

const addPropsToSingleChild = (child, props) =>
  isValidElement(child)
    ? cloneElement(child, { ...props })
    : typeof child === "function"
    ? child(props)
    : child;

export const addPropsToChild = (children, props) =>
  Array.isArray(children)
    ? children.map((child) => addPropsToSingleChild(child, props))
    : addPropsToSingleChild(children, props);

export const formatBlocks = (blocks) => {
  blocks = JSBI.BigInt(blocks);

  const unit = JSBI.lessThan(blocks, JSBI.BigInt(10 ** 3))
    ? ""
    : JSBI.lessThan(blocks, JSBI.BigInt(10 ** 6))
    ? "k"
    : JSBI.lessThan(blocks, JSBI.BigInt(10 ** 9))
    ? "M"
    : JSBI.lessThan(blocks, JSBI.BigInt(10 ** 12))
    ? "B"
    : "T";

  if (unit === "k") {
    blocks = JSBI.divide(blocks, JSBI.BigInt(10 ** 3));
  }

  if (unit === "M") {
    blocks = JSBI.divide(blocks, JSBI.BigInt(10 ** 6));
  }

  if (unit === "B") {
    blocks = JSBI.divide(blocks, JSBI.BigInt(10 ** 9));
  }

  if (unit === "T") {
    blocks = JSBI.divide(blocks, JSBI.BigInt(10 ** 12));
  }

  return { blocks, unit };
};
