import React, { Component } from "react"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Clear from "@mui/icons-material/Clear"
import { withTranslation } from "react-i18next"
import { GenericDialog } from "@igloocloud/igloosharedui"
import ClientContext from "../ClientContext"

export default withTranslation()(
  class BillingSettings extends Component {
    constructor(props) {
      super(props)

      this.state = {
        companyName: props.user.companyName || "",
      }
    }

    static contextType = ClientContext

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({
          companyName: nextProps.user.companyName || "",
        })
      }
    }

    setCompanyInfo = async () => {
      this.setState({ loading: true })

      await this.context.mutation
        .updateUser({
          companyName: this.state.companyName || null,
        })
        .then(this.props.close)
        .finally(() => this.setState({ loading: false }))
    }

    render() {
      const { open, close, t } = this.props
      const { loading, companyName } = this.state

      return (
        <GenericDialog
          title={t`Enter your company name`}
          open={open}
          close={close}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={t`Confirm`}
          containedButtonFunction={this.setCompanyInfo}
          containedButtonDisabled={
            !companyName.replace(/\s/g, "").length ||
            companyName.length >= 256 ||
            loading
          }
          containedButtonLoading={loading}
        >
          <>
            <TextField
              id="company-name"
              label={t`Company name`}
              value={companyName}
              variant="outlined"
              error={companyName.length >= 256}
              helperText={companyName.length >= 256 ? t`Too long!` : " "}
              onChange={(event) =>
                this.setState({
                  companyName: event.target.value,
                })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.setCompanyInfo()
                }
              }}
              style={{
                width: "100%",
              }}
              InputLabelProps={companyName ? { shrink: true } : {}}
              InputProps={{
                endAdornment: companyName && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({
                          companyName: "",
                        })
                      }
                      tabIndex="-1"
                      size="large">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        </GenericDialog>
      );
    }
  }
)
