import React, { Component } from "react"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Clear from "@mui/icons-material/Clear"
import { GenericDialog } from "@igloocloud/igloosharedui"
import ClientContext from "../ClientContext"
import { withTranslation } from "react-i18next"

export default withTranslation()(
  class BillingSettings extends Component {
    constructor(props) {
      super(props)

      this.state = {
        vatNumber: props.user.vatNumber || "",
      }
    }

    static contextType = ClientContext

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({
          vatNumber: nextProps.user.vatNumber || "",
        })
      }
    }

    setVatInfo = async () => {
      this.setState({ loading: true })

      await this.context.mutation
        .updateUser({
          vatNumber: this.state.vatNumber || null,
        })
        .then(this.props.close)
        .finally(() => this.setState({ loading: false }))
    }

    render() {
      const { open, close, t } = this.props
      const { loading, vatNumber } = this.state

      return (
        <GenericDialog
          title={t`Enter your VAT number`}
          open={open}
          close={close}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={t`Confirm`}
          containedButtonFunction={this.setVatInfo}
          containedButtonDisabled={
            !vatNumber.replace(/\s/g, "").length ||
            vatNumber.length >= 256 ||
            loading
          }
          containedButtonLoading={loading}
        >
          <TextField
            id="vat-number"
            label={t`VAT number`}
            value={vatNumber}
            variant="outlined"
            error={vatNumber.length >= 256}
            helperText={vatNumber.length >= 256 ? t`Too long!` : " "}
            onChange={(event) =>
              this.setState({
                vatNumber: event.target.value,
              })
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                this.setVatInfo()
              }
            }}
            style={{
              width: "100%",
            }}
            InputLabelProps={vatNumber ? { shrink: true } : {}}
            InputProps={{
              endAdornment: vatNumber && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        vatNumber: "",
                      })
                    }
                    tabIndex="-1"
                    size="large">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GenericDialog>
      );
    }
  }
)
