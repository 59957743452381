import React, { Component } from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Switch from "@mui/material/Switch"
import { Redirect } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { GenericDialog } from "@igloocloud/igloosharedui"
import ClientContext from "../ClientContext"

export default withTranslation()(
  class UnpairThingDialog extends Component {
    constructor(props) {
      super(props)

      this.state = {
        unpairThingOpen: false,
        reset: false,
        unpairThingLoading: false,
        redirect: "",
      }
    }

    static contextType = ClientContext

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({ reset: false })
      }
    }

    render() {
      const { open, close, thingId, t } = this.props
      const { reset, unpairThingLoading, redirect } = this.state

      if (redirect) {
        const tempRedirect = redirect

        this.setState({ redirect: "" })

        return <Redirect to={tempRedirect} />
      }

      return (
        <>
          <GenericDialog
            open={open}
            close={close}
            title={t`Unpair thing`}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Unpair`}
            containedButtonFunction={() => {
              this.setState({ unpairThingLoading: true })

              this.context.mutation
                .unpairThing({ id: thingId, reset })
                .then(() => {
                  close()
                })
                .finally(() => this.setState({ unpairThingLoading: false }))
            }}
            containedButtonLoading={unpairThingLoading}
            containedButtonDisabled={unpairThingLoading}
            isContainedButtonRed
          >
            {t`Be careful, the owner of this thing will be unable to access it after you unpair it` +
              "."}
            <List
              style={{
                padding: 0,
                marginTop: "16px",
              }}
            >
              <ListItem
                style={{
                  marginTop: "-3px",
                  marginBottom: "13px",
                  paddingLeft: 0,
                }}
              >
                <ListItemText primary={t`Reset thing`} />
                <ListItemSecondaryAction style={{ right: "-8px" }}>
                  <Switch
                    checked={reset}
                    onChange={(event) =>
                      this.setState({ reset: event.target.checked })
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </GenericDialog>
        </>
      )
    }
  }
)
