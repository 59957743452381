import React, { Component } from "react"
import Zoom from "@mui/material/Zoom"
import Fab from "@mui/material/Fab"
import AppBar from "@mui/material/AppBar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Notifications from "@mui/icons-material/Notifications"
import NotificationsNone from "@mui/icons-material/NotificationsNone"
import { Link, Redirect } from "react-router-dom"
import queryStringify from "querystringify"
import { withTranslation } from "react-i18next"
import {
  MainBody,
  NotificationDrawer,
  debounce,
} from "@igloocloud/igloosharedui"
import { Thing } from "@igloocloud/react-igloo"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import Edit from "@mui/icons-material/Edit"
import LinkOff from "@mui/icons-material/LinkOff"
import Delete from "@mui/icons-material/Delete"
import Divider from "@mui/material/Divider"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import ThingInfoDialog from "./ThingInfoDialog"
import CreateThingDialog from "./CreateThingDialog"
import UnpairThingDialog from "./UnpairThingDialog"
import DeleteThingDialog from "./DeleteThingDialog"
import MoreVert from "@mui/icons-material/MoreVert"
import tinyColor from "tinycolor2"

const {
  REACT_APP_TEXT_ON_MAIN_COLOR: textOnMainColor,
  REACT_APP_ERROR_COLOR: errorColor,
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
} = process.env

export default withTranslation()(
  class ThingBody extends Component {
    state = {
      greaterThan560: false,
      redirect: false,
      thingInfoOpen: false,
      editThingOpen: false,
      unpairThingOpen: false,
      deleteThingOpen: false,
      drawerOpen: false,
      menuOpen: false,
    }

    updateDimensions = () => {
      if (window.innerWidth > 560) {
        !this.state.greaterThan560 && this.setState({ greaterThan560: true })
      } else {
        this.state.greaterThan560 && this.setState({ greaterThan560: false })
      }
    }

    componentDidMount() {
      this.updateDimensions()
      window.addEventListener("resize", debounce(this.updateDimensions))
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions))
    }

    render() {
      const { mobile, id, logOut, t } = this.props
      const {
        drawerOpen,
        greaterThan560,
        redirect,
        thingInfoOpen,
        editThingOpen,
        unpairThingOpen,
        deleteThingOpen,
        menuOpen,
      } = this.state

      if (redirect) {
        const tempRedirect = redirect

        this.setState({ redirect: false })

        return <Redirect to={tempRedirect} />
      }

      return (
        <Thing
          id={id}
          fields={[
            "type",
            "firmware",
            "owner{email}",
            "notificationCount",
            "createdAt",
            "updatedAt",
            "pairCode",
            "token",
          ]}
          onDelete={(id) => {
            if (queryStringify.parse(window.location.search).id === id) {
              this.setState({ redirect: "/things" })
            }
          }}
        >
          {({ data: thing, error }) => (
            <>
              {!mobile && (
                <>
                  <div
                    style={{ height: "64px", display: "flex" }}
                    className="notSelectable"
                  >
                    <IconButton
                      style={{ margin: "8px" }}
                      component={Link}
                      to="/things"
                      size="large"
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant="h5"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineHeight: "64px",
                        width: "calc(100% - 272px)",
                      }}
                    >
                      {thing?.type}
                    </Typography>
                    <div style={{ margin: "8px" }}>
                      <IconButton
                        onClick={() => this.setState({ thingInfoOpen: true })}
                        disabled={!thing}
                        size="large"
                      >
                        <InfoOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => this.setState({ editThingOpen: true })}
                        disabled={!thing}
                        size="large"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => this.setState({ unpairThingOpen: true })}
                        disabled={!thing?.owner?.email}
                        size="large"
                      >
                        <LinkOff />
                      </IconButton>
                      <IconButton
                        onClick={() => this.setState({ deleteThingOpen: true })}
                        disabled={!thing}
                        size="large"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                  <Divider />
                </>
              )}
              {error &&
                (error.message ===
                  "GraphQL error: The requested resource does not exist" ||
                  error.message.startsWith(
                    'Argument "id" has invalid value'
                  )) && <Redirect to="/things" />}
              <NotificationDrawer
                isPortal
                open={drawerOpen}
                setOpen={(drawerOpen) => this.setState({ drawerOpen })}
                thingId={id}
                logOut={logOut}
                notificationCount={thing?.notificationCount}
              />
              <div
                style={{
                  height: mobile ? "calc(100% - 64px)" : "calc(100% - 66px)",
                }}
              >
                <MainBody
                  isPortal
                  thingId={id}
                  setRedirect={(redirect) =>
                    redirect && this.setState({ redirect })
                  }
                  logOut={logOut}
                  mobile={mobile}
                />
              </div>
              <Zoom in={thing && !mobile}>
                <Fab
                  onClick={() => this.setState({ drawerOpen: true })}
                  color="secondary"
                  variant={greaterThan560 ? "extended" : "round"}
                  style={{
                    position: "absolute",
                    right: "16px",
                    bottom: mobile
                      ? "calc(40px + env(safe-area-inset-bottom))"
                      : "calc(16px + env(safe-area-inset-bottom))",
                    zIndex: 1200,
                  }}
                  id="notification-fab"
                >
                  {thing?.notificationCount ? (
                    <Notifications
                      style={greaterThan560 ? { marginRight: "4px" } : {}}
                    />
                  ) : (
                    <NotificationsNone
                      style={greaterThan560 ? { marginRight: "4px" } : {}}
                    />
                  )}
                  {greaterThan560 && t`Notifications`}
                </Fab>
              </Zoom>
              {mobile ? (
                <AppBar
                  position="relative"
                  color="primary"
                  style={{
                    height: "calc(64px + env(safe-area-inset-bottom))",
                    paddingBottom: "env(safe-area-inset-bottom)",
                    boxShadow:
                      "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <IconButton
                      style={{ margin: "8px", color: textOnMainColor }}
                      component={Link}
                      to="/things"
                      size="large"
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      className="notSelectable"
                      variant="h5"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 168px)",
                      }}
                    >
                      {thing?.type}
                    </Typography>
                    <IconButton
                      style={{
                        color: textOnMainColor,
                      }}
                      onClick={() => this.setState({ drawerOpen: true })}
                      size="large"
                    >
                      {thing?.notificationCount ? (
                        <Notifications />
                      ) : (
                        <NotificationsNone />
                      )}
                    </IconButton>
                    <IconButton
                      style={{
                        color: tinyColor(textOnMainColor).setAlpha(
                          thing?.type ? 1 : 0.26
                        ),
                      }}
                      disabled={!thing?.type}
                      onClick={() => this.setState({ menuOpen: true })}
                      size="large"
                    >
                      <MoreVert />
                    </IconButton>
                  </div>
                </AppBar>
              ) : (
                ""
              )}
              {thing && (
                <>
                  <ThingInfoDialog
                    open={thingInfoOpen}
                    close={() => this.setState({ thingInfoOpen: false })}
                    thing={thing}
                  />
                  <CreateThingDialog
                    open={editThingOpen}
                    close={() => this.setState({ editThingOpen: false })}
                    edit
                    thingId={thing.id}
                    currentType={thing.type}
                    currentFirmware={thing.firmware}
                  />
                  <UnpairThingDialog
                    open={unpairThingOpen}
                    close={() => this.setState({ unpairThingOpen: false })}
                    thingId={thing.id}
                  />
                  <DeleteThingDialog
                    open={deleteThingOpen}
                    close={() => this.setState({ deleteThingOpen: false })}
                    thingId={thing.id}
                  />
                </>
              )}
            </>
          )}
          <SwipeableDrawer
            variant="temporary"
            anchor="bottom"
            open={menuOpen}
            onOpen={() => this.setState({ menuOpen: true })}
            onClose={() => this.setState({ menuOpen: false })}
            disableBackdropTransition={false}
            disableDiscovery
            disableSwipeToOpen
            PaperProps={{
              style: {
                backgroundColor,
              },
            }}
          >
            <MenuItem
              onClick={() =>
                this.setState({ thingInfoOpen: true, menuOpen: false })
              }
            >
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <ListItemText
                primary={t`Information`}
                disableTypography
                style={{ color: textOnBackgroundColor }}
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                this.setState({ editThingOpen: true, menuOpen: false })
              }
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText
                primary={t`Edit`}
                disableTypography
                style={{ color: textOnBackgroundColor }}
              />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ unpairThingOpen: true, menuOpen: false })
              }
            >
              <ListItemIcon>
                <LinkOff />
              </ListItemIcon>
              <ListItemText
                primary={t`Unpair`}
                disableTypography
                style={{ color: textOnBackgroundColor }}
              />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ deleteThingOpen: true, menuOpen: false })
              }
            >
              <ListItemIcon>
                <Delete style={{ color: errorColor }} />
              </ListItemIcon>
              <ListItemText
                primary={t`Delete`}
                disableTypography
                style={{ color: errorColor }}
              />
            </MenuItem>
          </SwipeableDrawer>
        </Thing>
      )
    }
  }
)
