import React, { Component } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import ClientContext from "../ClientContext"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Clear from "@mui/icons-material/Clear"
import { Redirect } from "react-router-dom"
import { withTranslation } from "react-i18next"

export default withTranslation()(
  class CreateThingDialog extends Component {
    constructor(props) {
      super(props)

      this.state = {
        type: "",
        typeEmpty: "",
        typeError: "",
        firmware: "",
        firmwareEmpty: "",
        redirect: "",
      }
    }

    static contextType = ClientContext

    createThing = () => {
      const { t } = this.props
      const { type, firmware } = this.state

      if (
        type.replace(/\s/g, "").length &&
        type.length < 256 &&
        firmware.length < 256
      ) {
        this.setState({ createThingLoading: true })

        this.context.mutation
          .createThing({
            type,
            firmware: firmware.trim() || null,
          })
          .then(({ id }) => {
            this.props.close()

            this.setState({ redirect: "/things?id=" + id })
          })
          .catch((error) =>
            error.toString() ===
            "Error: GraphQL error: You reached the maximum amount of things you can create with this plan"
              ? this.setState({
                  typeError: t`You created too many things`,
                })
              : this.setState({ typeError: t`Error` })
          )
          .finally(() => this.setState({ createThingLoading: false }))
      }
    }

    editThing = () => {
      const { type, firmware } = this.state

      if (
        type.replace(/\s/g, "").length &&
        type.length < 256 &&
        firmware.length < 256
      ) {
        this.setState({ createThingLoading: true })

        this.context.mutation
          .updateThing({
            id: this.props.thingId,
            type,
            firmware: firmware.trim() || null,
          })
          .then(this.props.close)
          .finally(() => this.setState({ createThingLoading: false }))
      }
    }

    componentWillReceiveProps(nextProps) {
      // closing the dialog resets the textfield state
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({
          type: nextProps.currentType || "",
          typeEmpty: "",
          typeError: "",
          firmware: nextProps.currentFirmware || "",
          firmwareEmpty: "",
        })
      }
    }

    render() {
      const { open, close, edit, t } = this.props
      const {
        type,
        typeEmpty,
        typeError,
        firmware,
        redirect,
        createThingLoading,
      } = this.state

      if (redirect) {
        const tempRedirect = redirect

        this.setState({ redirect: "" })

        return <Redirect push to={tempRedirect} />
      }

      return (
        <GenericDialog
          open={open}
          close={close}
          title={edit ? t`Edit thing` : t`Create thing`}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={edit ? t`Edit` : t`Create`}
          containedButtonFunction={edit ? this.editThing : this.createThing}
          containedButtonDisabled={
            !type.replace(/\s/g, "").length ||
            type.length >= 256 ||
            firmware.length >= 256 ||
            createThingLoading
          }
          containedButtonLoading={createThingLoading}
        >
          <TextField
            id="thing-type"
            label={t`Type`}
            value={type}
            variant="outlined"
            error={typeEmpty || typeError || type.length >= 256}
            helperText={
              type.length >= 256
                ? t`Too long!`
                : typeEmpty
                ? t`This field is required`
                : typeError
                ? typeError
                : " "
            }
            onChange={(event) =>
              this.setState({
                type: event.target.value,
                typeEmpty: event.target.value.replace(/\s/g, "") === "",
                typeError: "",
              })
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (edit) this.editThing()
                else this.createThing()
              }
            }}
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
            InputLabelProps={type ? { shrink: true } : {}}
            InputProps={{
              endAdornment: type && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        type: "",
                        typeEmpty: true,
                        typeError: "",
                      })
                    }
                    tabIndex="-1"
                    size="large">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            id="firmware"
            label={t`Firmware`}
            value={firmware}
            variant="outlined"
            error={firmware.length >= 256}
            helperText={firmware.length >= 256 ? t`Too long!` : " "}
            onChange={(event) =>
              this.setState({
                firmware: event.target.value,
              })
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (edit) this.editThing()
                else this.createThing()
              }
            }}
            style={{
              width: "100%",
              marginBottom: "16px",
            }}
            InputLabelProps={firmware ? { shrink: true } : {}}
            InputProps={{
              endAdornment: firmware && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        firmware: "",
                      })
                    }
                    tabIndex="-1"
                    size="large">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GenericDialog>
      );
    }
  }
)
