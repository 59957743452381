import React, { Component } from "react"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import { withTranslation } from "react-i18next"
import StripeInput from "./StripeInput"

export default withTranslation()(
  class StripeElementWrapper extends Component {
    state = {
      focused: false,
      empty: true,
      error: false,
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.stripeError !== this.props.stripeError) {
        this.setState({ error: { message: nextProps.stripeError } })
      }
    }

    render() {
      const { component, label, setComplete, setError, isLastElement, t } =
        this.props
      const { error, focused, empty } = this.state

      return (
        <div>
          <FormControl
            variant="outlined"
            fullWidth
            style={{
              marginBottom: isLastElement ? 0 : "16px",
            }}
          >
            <InputLabel
              focused={focused}
              shrink={focused || !empty}
              error={!!error}
            >
              {label}
            </InputLabel>
            <OutlinedInput
              {...(focused || !empty ? { label } : {})}
              notched
              fullWidth
              inputComponent={StripeInput}
              onFocus={() => this.setState({ focused: true })}
              onBlur={() => this.setState({ focused: false })}
              inputProps={{
                component,
                handleChange: ({ error, empty, complete }) => {
                  this.setState({
                    error: empty
                      ? new Error(t`This field is required`)
                      : error && new Error(t(error.message.replace(".", ""))),
                    empty,
                  })
                  setComplete(complete)
                  setError(error)
                },
              }}
              style={{
                height: "19px",
                padding: "28px 14px",
              }}
              error={!!error}
            />
            <FormHelperText error={!!error}>
              {error ? error.message : " "}
            </FormHelperText>
          </FormControl>
        </div>
      )
    }
  }
)
