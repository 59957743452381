import React, { Component } from "react"
import {
  OfflineScreen,
  theme,
  UnauthenticatedApp,
  Error404,
  SharedUIProvider,
  debounce,
  importAll,
} from "@igloocloud/igloosharedui"
import Client from "@igloocloud/igloo.js"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import { Switch, Redirect, Route } from "react-router-dom"
import querystringify from "querystringify"
import ClientContext from "./ClientContext"
import UserFetcher from "./UserFetcher"
import "./App.css"
import logo from "./assets/logo.svg"
import backgroundLogo from "./assets/background-logo.svg"
import unauthenticatedLogo from "./assets/unauthenticated-logo.svg"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { version } from "../package.json"

const { REACT_APP_NAME: appName } = process.env

const setupServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("serviceWorker.js")
  }
}

export default withTranslation()(
  class App extends Component {
    constructor(props) {
      super(props)

      if (!localStorage.getItem("accountList")) {
        localStorage.setItem("accountList", "[]")
      }
      if (!localStorage.getItem("sortingPreferences")) {
        localStorage.setItem("sortingPreferences", "[]")
      }
      if (!localStorage.getItem("sortDirection")) {
        localStorage.setItem("sortDirection", "[]")
      }
      if (!localStorage.getItem("language")) {
        localStorage.setItem(
          "language",
          (navigator.language || navigator.userLanguage).slice(0, 2)
        )
      }

      const bearer =
        localStorage.getItem("accountList") &&
        localStorage.getItem("userId") &&
        JSON.parse(localStorage.getItem("accountList")).filter(
          (account) => account.id === localStorage.getItem("userId")
        )[0]
          ? JSON.parse(localStorage.getItem("accountList")).filter(
              (account) => account.id === localStorage.getItem("userId")
            )[0].token
          : ""

      if (!sessionStorage.getItem("loginBackground")) {
        const importAll = (require) =>
          require.keys().reduce((acc, next) => {
            acc[next.replace("./", "")] = require(next)
            return acc
          }, {})

        const images = importAll(
          require.context(
            "./assets/loginBackgrounds",
            false,
            /\.(png|jpe?g|svg)$/
          )
        )

        sessionStorage.setItem(
          "loginBackground",
          Math.floor(Math.random() * Object.keys(images).length)
        )
      }

      this.state = {
        mobile: null,
        greaterThan1200: null,
        bearer,
        redirectTo: "",
        isMobile: null,
        isOnline: navigator.onLine,
      }

      setupServiceWorker()
    }

    updateDimensions = () => {
      if (window.innerWidth < 600) {
        !this.state.mobile && this.setState({ mobile: true })
      } else {
        this.state.mobile && this.setState({ mobile: false })
      }

      if (window.innerWidth > 1200) {
        !this.state.greaterThan1200 && this.setState({ greaterThan1200: true })
      } else {
        this.state.greaterThan1200 && this.setState({ greaterThan1200: false })
      }

      if (window.innerWidth > 1600) {
        !this.state.greaterThan1600 && this.setState({ greaterThan1600: true })
      } else {
        this.state.greaterThan1600 && this.setState({ greaterThan1600: false })
      }
    }

    logOut = (deleted) => {
      let currentAccountList = JSON.parse(localStorage.getItem("accountList"))

      deleted
        ? (currentAccountList = currentAccountList.filter(
            (account) => account.id !== localStorage.getItem("userId")
          ))
        : currentAccountList.filter(
            (account) => account.id === localStorage.getItem("userId")
          )[0] &&
          (currentAccountList.filter(
            (account) => account.id === localStorage.getItem("userId")
          )[0].token = "")

      localStorage.setItem("accountList", JSON.stringify(currentAccountList))

      localStorage.setItem("userId", "")

      this.setState({
        bearer: "",
        loggedOut: true,
        loginEmail: "",
        signupEmail: "",
      })
    }

    changeAccount = (userId, redirect) => {
      this.setState({
        bearer: "",
        loggedOut: true,
        userId,
        redirect,
        signupEmail: "",
      })
      localStorage.setItem("userId", "")
    }

    componentDidMount() {
      this.updateDimensions()
      window.addEventListener("resize", debounce(this.updateDimensions))
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions))
    }

    render() {
      const { i18n } = this.props
      const { isOnline } = this.state

      moment.updateLocale(
        i18n.language,
        i18n.language === "en"
          ? require("moment/locale/en-gb")
          : require("moment/locale/" + i18n.language)
      )

      if (
        querystringify.parse(window.location.search).user &&
        localStorage.getItem("accountList") &&
        window.location.pathname !== "/login"
      ) {
        if (
          JSON.parse(localStorage.getItem("accountList")).find(
            (account) =>
              account.id === querystringify.parse(window.location.search).user
          )
        ) {
          localStorage.setItem(
            "userId",
            querystringify.parse(window.location.search).user
          )

          this.setState({
            bearer: JSON.parse(localStorage.getItem("accountList")).find(
              (account) =>
                account.id === querystringify.parse(window.location.search).user
            ).token,
          })
        }

        return <Redirect to={window.location.pathname} />
      }

      if (this.state.redirectTo) {
        let address = this.state.redirectTo
        this.setState({ redirectTo: "" })
        return <Redirect push to={address} />
      }

      if (this.state.redirectToHidden) {
        let address = this.state.redirectToHidden
        this.setState({ redirectToHidden: "" })
        return <Redirect to={address} />
      }

      return (
        <SharedUIProvider
          value={{
            ...process.env,
            i18n,
            moment,
            logo,
            backgroundLogo,
            unauthenticatedLogo,
            loginBackgrounds: Object.values(
              importAll(
                require.context(
                  "./assets/loginBackgrounds",
                  false,
                  /\.(png|jpe?g|svg)$/
                )
              )
            ).map((image) => image.default),
            version,
          }}
        >
          <ClientContext.Provider
            value={
              new Client(
                this.state.bearer,
                localStorage.getItem("server")
                  ? (localStorage.getItem("serverUnsecure") === "true"
                      ? "http://"
                      : "https://") +
                    localStorage.getItem("server") +
                    "/graphql"
                  : `https://v1.igloo.ooo/graphql`
              )
            }
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme(process.env)}>
                {isOnline ? (
                  <Switch>
                    <Route
                      path={
                        !localStorage.getItem("server") ||
                        localStorage.getItem("server").includes(".igloo.ooo")
                          ? ["/", "/things", "/payments"]
                          : ["/", "/things"]
                      }
                      exact
                    >
                      {this.state.bearer ? (
                        <UserFetcher
                          mobile={this.state.mobile}
                          greaterThan1200={this.state.greaterThan1200}
                          greaterThan1600={this.state.greaterThan1600}
                          bearer={this.state.bearer}
                          logOut={this.logOut}
                          changeAccount={this.changeAccount}
                          forceUpdate={() => this.forceUpdate()}
                        />
                      ) : (
                        <Redirect
                          to={
                            JSON.parse(localStorage.getItem("accountList"))[0]
                              ? "/accounts"
                              : "/signup"
                          }
                        />
                      )}
                    </Route>
                    <Route path="/login">
                      <UnauthenticatedApp
                        isLogin
                        isPortal
                        forceUpdate={() => this.forceUpdate()}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setHiddenRedirect={(redirectToHidden) =>
                          this.setState({ redirectToHidden })
                        }
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/signup">
                      <UnauthenticatedApp
                        isPortal
                        forceUpdate={() => this.forceUpdate()}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/accounts">
                      <UnauthenticatedApp
                        isAccountSwitcher
                        isPortal
                        forceUpdate={() => this.forceUpdate()}
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route path="/recovery">
                      <UnauthenticatedApp
                        isRecovery
                        changeBearer={(bearer) => this.setState({ bearer })}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                    <Route>
                      <Error404
                        isMobile={this.state.isMobile}
                        setRedirect={(redirectTo) =>
                          this.setState({ redirectTo })
                        }
                        appName={appName}
                      />
                    </Route>
                  </Switch>
                ) : (
                  <OfflineScreen isMobile={this.state.isMobile} isPortal />
                )}
              </ThemeProvider>
            </StyledEngineProvider>
          </ClientContext.Provider>
        </SharedUIProvider>
      )
    }
  }
)
