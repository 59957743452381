import React, { useState } from "react"
import moment from "moment"
import { withTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import InputBase from "@mui/material/InputBase"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import LinearProgress from "@mui/material/LinearProgress"
import Skeleton from "@mui/material/Skeleton"
import { withStyles } from "@mui/styles"
import Add from "@mui/icons-material/Add"
import Search from "@mui/icons-material/Search"
import { Link } from "react-router-dom"
import ThingBody from "./ThingBody"
import querystringify from "querystringify"
import CreateThingDialog from "./CreateThingDialog"
import { ErrorScreen } from "@igloocloud/igloosharedui"
import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import { createTheme, adaptV4Theme } from "@mui/material/styles"

const {
  REACT_APP_SECONDARY_BACKGROUND_COLOR: secondaryBackgroundColor,
  REACT_APP_TEXT_ON_SECONDARY_BACKGROUND_COLOR: textOnSecondaryBackgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
} = process.env

export default withTranslation()(
  withStyles(() => ({
    search: {
      position: "relative",
      borderRadius: 18,
      backgroundColor: secondaryBackgroundColor,
      width: "calc(100% - 32px)",
      "&:hover": {
        "&:!disabled": {
          backgroundColor: secondaryBackgroundColor,
        },
      },
      color: textOnSecondaryBackgroundColor,
    },
    searchIcon: {
      width: "48px",
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      padding: "8px 8px 8px 48px",
      width: 400,
    },
  }))(
    ({
      developerThingsProps,
      userProps,
      firmwareProps,
      logOut,
      t,
      classes,
      mobile,
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
    }) => {
      const [createThingOpen, setCreateThingOpen] = useState(false)
      const [loading, setLoading] = useState(false)
      const [search, setSearch] = useState("")
      const {
        REACT_APP_MAIN_COLOR: color,
        REACT_APP_TEXT_ON_MAIN_COLOR: textColor,
      } = process.env
      let rows = []

      const queryMore = async (
        fetchFunction,
        developerThingCount,
        thingsData
      ) => {
        if (!queryMore.locked && developerThingCount > thingsData.length) {
          queryMore.locked = true

          setLoading(true)

          try {
            await fetchFunction(
              developerThingCount - thingsData.length >= 20
                ? 20
                : developerThingCount % 20
            )
          } finally {
            queryMore.locked = false

            setLoading(false)
          }
        }
      }

      if (developerThingsProps.data && userProps.data) {
        rows = developerThingsProps.data
          .filter(
            (thing) =>
              thing.type.toLowerCase().includes(search.toLowerCase()) ||
              thing.owner?.email.toLowerCase().includes(search.toLowerCase()) ||
              thing.id.toLowerCase().includes(search.toLowerCase())
          )
          .map(
            ({ id, type, createdAt, updatedAt, firmware, online, owner }) => ({
              id,
              type,
              firmware,
              online: online ? t`Yes` : t`No`,
              ownerEmail: owner?.email,
              createdAt,
              updatedAt,
            })
          )
      }

      const cellStyle = {
        whiteSpace: "nowrap",
      }

      return querystringify.parse(window.location.search).id ? (
        <ThingBody
          id={querystringify.parse(window.location.search).id}
          logOut={logOut}
        />
      ) : (
        <>
          <div
            style={{
              height: "64px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: mobile ? "100%" : "50%",
                height: "64px",
                display: mobile ? "flex" : "",
                justifyContent: mobile ? "center" : "",
              }}
            >
              <div
                className={classes.search}
                style={{
                  width: mobile ? "calc(100% - 80px)" : "100%",
                  maxWidth: "400px",
                  margin: "14.5px 0 14.5px 16px",
                }}
              >
                <div className={classes.searchIcon}>
                  <Search
                    style={
                      userProps.loading ||
                      userProps.error ||
                      firmwareProps.loading ||
                      firmwareProps.error ||
                      developerThingsProps.loading ||
                      developerThingsProps.error ||
                      (developerThingsProps.data &&
                        !developerThingsProps.data[0])
                        ? { color: "rgb(138, 138, 138)" }
                        : {}
                    }
                  />
                </div>
                <InputBase
                  placeholder={t`Search`}
                  className="notSelectable"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  disabled={
                    userProps.loading ||
                    userProps.error ||
                    firmwareProps.loading ||
                    firmwareProps.error ||
                    developerThingsProps.loading ||
                    developerThingsProps.error ||
                    (developerThingsProps.data && !developerThingsProps.data[0])
                  }
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event

                    setSearch(value)

                    queryMore(
                      developerThingsProps.fetchMore,
                      userProps.data.developerThingCount,
                      developerThingsProps.data
                    )
                  }}
                />
              </div>
              {mobile && (
                <IconButton
                  style={{ width: "48px", height: "48px", margin: "8px" }}
                  onClick={() => setCreateThingOpen(true)}
                  size="large"
                >
                  <Add />
                </IconButton>
              )}
            </div>
            {!mobile && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "50%",
                  marginRight: "16px",
                }}
              >
                <StyledEngineProvider injectFirst>
                  <ThemeProvider
                    theme={createTheme(
                      adaptV4Theme({
                        palette: {
                          primary: {
                            main: color,
                            contrastText: textColor,
                          },
                        },
                      })
                    )}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      style={{ borderRadius: "18px" }}
                      onClick={() => setCreateThingOpen(true)}
                    >
                      {t`Thing`}
                    </Button>
                  </ThemeProvider>
                </StyledEngineProvider>
              </div>
            )}
          </div>
          {loading && (
            <LinearProgress
              style={
                mobile
                  ? { position: "absolute", top: "60px", width: "100%" }
                  : { marginTop: "-4px" }
              }
            />
          )}
          {developerThingsProps.error ? (
            <ErrorScreen error={developerThingsProps.error} />
          ) : (
            <div
              style={{ height: "calc(100% - 64px)", overflowY: "auto" }}
              onScroll={(event) => {
                if (
                  event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 600 &&
                  userProps.data &&
                  developerThingsProps.data
                )
                  queryMore(
                    developerThingsProps.fetchMore,
                    userProps.data.developerThingCount,
                    developerThingsProps.data
                  )
              }}
            >
              {rows.length || developerThingsProps.loading ? (
                <Table stickyHeader>
                  <TableHead className="notSelectable">
                    <TableRow>
                      {[
                        { id: "id", name: t`ID`, width: "120px" },
                        { id: "type", name: t`Type` },
                        { id: "ownerEmail", name: t`Owner`, width: "25%" },
                        { id: "createdAt", name: t`Created`, width: "256px" },
                        {
                          id: "updatedAt",
                          name: t`Last updated`,
                          width: "256px",
                        },
                      ].map(({ id, name, width }) => (
                        <TableCell style={{ backgroundColor: "#fff", width }}>
                          <TableSortLabel
                            active={sortBy === id && rows.length}
                            direction={
                              sortBy !== id || sortDirection === "ASCENDING"
                                ? "asc"
                                : "desc"
                            }
                            onClick={() => {
                              const isAsc =
                                sortBy === id && sortDirection === "ASCENDING"
                              setSortDirection(
                                isAsc ? "DESCENDING" : "ASCENDING"
                              )
                              setSortBy(id)

                              localStorage.setItem(
                                "sortingPreferences",
                                JSON.stringify([
                                  ...JSON.parse(
                                    localStorage.getItem("sortingPreferences")
                                  ).filter(
                                    ({ id }) =>
                                      id !== localStorage.getItem("userId")
                                  ),
                                  {
                                    id: localStorage.getItem("userId"),
                                    sortBy: id,
                                    sortDirection: isAsc
                                      ? "DESCENDING"
                                      : "ASCENDING",
                                  },
                                ])
                              )
                            }}
                          >
                            <b>{name}</b>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(developerThingsProps.loading
                      ? new Array(20).fill({})
                      : rows.sort((a, b) =>
                          a[sortBy]?.toLowerCase() > b[sortBy]?.toLowerCase()
                            ? sortDirection === "ASCENDING"
                              ? 1
                              : -1
                            : a[sortBy]?.toLowerCase() ===
                              b[sortBy]?.toLowerCase()
                            ? 0
                            : sortDirection === "ASCENDING"
                            ? -1
                            : 1
                        )
                    ).map((row) => (
                      <TableRow
                        key={row.id}
                        className="notSelectable"
                        style={{ textDecoration: "none" }}
                        {...(developerThingsProps.loading
                          ? {}
                          : {
                              hover: true,
                              component: Link,
                              to: "/things?id=" + row.id,
                            })}
                      >
                        <TableCell style={{ ...cellStyle, width: "120px" }}>
                          {developerThingsProps.loading ? (
                            <Skeleton />
                          ) : (
                            row.id.split("-")[0]
                          )}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {developerThingsProps.loading ? (
                            <Skeleton />
                          ) : row.type.substring(0, 32).length <
                            row.type.length ? (
                            row.type.substring(0, 32) + "..."
                          ) : (
                            row.type
                          )}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, width: "25%" }}>
                          {developerThingsProps.loading ? (
                            <Skeleton />
                          ) : row.ownerEmail?.substring(0, 32).length <
                            row.ownerEmail?.length ? (
                            row.ownerEmail?.substring(0, 32) + "..."
                          ) : (
                            row.ownerEmail
                          )}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, width: "256px" }}>
                          {developerThingsProps.loading ? (
                            <Skeleton />
                          ) : (
                            moment(row.createdAt).format(
                              (userProps.data.dateFormat === "DMY"
                                ? "DD/MM/YYYY"
                                : userProps.data.dateFormat === "MDY"
                                ? "MM/DD/YYYY"
                                : userProps.data.dateFormat === "YMD"
                                ? "YYYY/MM/DD"
                                : "YYYY/DD/MM") +
                                " " +
                                (userProps.data.timeFormat === "H12"
                                  ? "hh:mm:ss A"
                                  : "HH:mm:ss")
                            )
                          )}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, width: "256px" }}>
                          {developerThingsProps.loading ? (
                            <Skeleton />
                          ) : (
                            moment(row.updatedAt).format(
                              (userProps.data.dateFormat === "DMY"
                                ? "DD/MM/YYYY"
                                : userProps.data.dateFormat === "MDY"
                                ? "MM/DD/YYYY"
                                : userProps.data.dateFormat === "YMD"
                                ? "YYYY/MM/DD"
                                : "YYYY/DD/MM") +
                                " " +
                                (userProps.data.timeFormat === "H12"
                                  ? "hh:mm:ss A"
                                  : "HH:mm:ss")
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  variant="h5"
                  className="notSelectable defaultCursor"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "32px",
                    marginBottom: "32px",
                    color: textOnMainBackgroundColor,
                  }}
                >
                  {t`No things`}
                </Typography>
              )}
            </div>
          )}
          {userProps.data && (
            <CreateThingDialog
              open={createThingOpen}
              close={() => setCreateThingOpen(false)}
            />
          )}
        </>
      )
    }
  )
)
