import React, { useState, useContext } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import ClientContext from "../ClientContext"
import moment from "moment"
import Moment from "react-moment"
import clipboardCopy from "clipboard-copy"
import { downloadText } from "download.js"
import { svg2png } from "svg-png-converter"
import CircularProgress from "@mui/material/CircularProgress"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import SvgIcon from "@mui/material/SvgIcon"
import Zoom from "@mui/material/Zoom"
import { withTranslation } from "react-i18next"

const {
  REACT_APP_NAME: appName,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
} = process.env

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export default withTranslation()(({ open, close, thing, t }) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [showCopy, setShowCopy] = useState(true)
  const [copyRan, setCopyRan] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showDownload, setShowDownload] = useState(true)
  const [downloadRan, setDownloadRan] = useState(false)

  const client = useContext(ClientContext)

  return (
    <GenericDialog
      title={t`Thing information`}
      open={open}
      close={close}
      textButton={t`Close`}
      textButtonFunction={close}
      selectable
      noHorizontalPadding
    >
      <div style={{ padding: "0 24px" }}>
        <b>{t`ID`}: </b>
        {thing.id}
        <br />
        <b>{t`Pair code`}: </b>
        {thing.pairCode}
        <br />
        <b>{t`Type`}: </b>
        {thing.type}
        <br />
        {thing.firmware && (
          <>
            <b>{t`Firmware`}: </b>
            {thing.firmware}
            <br />
          </>
        )}
        <b>{t`Created`}: </b>
        <Moment fromNow>
          {moment.utc(thing.createdAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
        </Moment>
        <br />
        <b>{t`Last seen` + ": "}</b>
        {thing.online ? (
          t`now`
        ) : (
          <Moment fromNow>
            {moment.utc(thing.updatedAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
          </Moment>
        )}
        <br />
      </div>
      <List style={{ paddingTop: "16px" }}>
        <ListItem
          button
          onClick={async () => {
            clipboardCopy(thing.token)

            //this way the zoom animation isn't shown when the dialog opens
            setCopyRan(true)

            setShowCopy(false)
            setShowConfirm(true)
            await sleep(1000)
            setShowConfirm(false)
            setShowCopy(true)
          }}
        >
          <ListItemIcon>
            {showConfirm && (
              <Zoom in={showConfirm}>
                <SvgIcon style={{ margin: "0 8px" }}>
                  <svg
                    style={{
                      width: "24px",
                      height: "24px",
                      color: textOnBackgroundColor,
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                  </svg>
                </SvgIcon>
              </Zoom>
            )}
            {showCopy && (
              <Zoom
                in={showCopy}
                timeout={
                  copyRan ? { enter: 225, exit: 255 } : { enter: 0, exit: 0 }
                }
              >
                <SvgIcon style={{ margin: "0 8px" }}>
                  <svg
                    style={{
                      width: "24px",
                      height: "24px",
                      color: textOnBackgroundColor,
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                  </svg>
                </SvgIcon>
              </Zoom>
            )}
          </ListItemIcon>
          <ListItemText
            style={{
              cursor: "pointer",
            }}
          >
            {t`Copy token`}
          </ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={async () => {
            //this way the zoom animation isn't shown when the dialog opens
            setDownloadRan(true)
            setShowDownload(false)
            setShowLoading(true)

            await downloadText(
              appName + " - " + thing.type + ".jpeg",
              await svg2png({
                input: (await client.query.thing(thing.id).qrCode).trim(),
                encoding: "buffer",
                format: "jpeg",
              })
            )

            setShowLoading(false)
            setShowDownload(true)
          }}
        >
          <ListItemIcon>
            {showLoading && (
              <CircularProgress
                size={24}
                color="primary"
                style={{
                  position: "absolute",
                  top: "50%",
                  marginTop: -12,
                  marginLeft: "8px",
                }}
              />
            )}
            {showDownload && (
              <Zoom
                in={showDownload}
                timeout={
                  downloadRan
                    ? { enter: 225, exit: 255 }
                    : { enter: 0, exit: 0 }
                }
              >
                <SvgIcon style={{ margin: "0 8px" }}>
                  <svg
                    style={{
                      width: "24px",
                      height: "24px",
                      color: textOnBackgroundColor,
                    }}
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                      <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
                    </g>
                  </svg>
                </SvgIcon>
              </Zoom>
            )}
          </ListItemIcon>
          <ListItemText
            style={{
              cursor: "pointer",
            }}
          >
            {t`Download QR code`}
          </ListItemText>
        </ListItem>
      </List>
    </GenericDialog>
  )
})
