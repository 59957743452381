import React, { Component } from "react";
import PaymentError from "../payments/PaymentError";
import ThingsTile from "./ThingsTile";
import StorageTile from "./StorageTile";
import ThroughputTile from "./ThroughputTile";
import DiskTile from "./DiskTile";
import { debounce } from "@igloocloud/igloosharedui";

const { REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor } = process.env;

export default class Home extends Component {
  state = { rows: null };

  updateDimensions = () => {
    const { rows } = this.state;

    if (window.innerWidth >= 1896) {
      rows !== 3 && this.setState({ rows: 3 });
    } else if (window.innerWidth >= 880) {
      rows !== 2 && this.setState({ rows: 2 });
    } else {
      rows !== 1 && this.setState({ rows: 1 });
    }
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", debounce(this.updateDimensions));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", debounce(this.updateDimensions));
  }

  render() {
    const { mobile, userProps } = this.props;
    const { rows } = this.state;

    return (
      <div
        style={{
          width: "calc(100% - 32px)",
          margin: "0 auto",
          padding: "16px 0",
          maxWidth: rows === 1 ? "576px" : rows === 2 ? "1168px" : "1752px",
          backgroundColor,
        }}
      >
        <PaymentError mobile={mobile} user={userProps.data} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              rows === 1
                ? "100%"
                : rows === 2
                ? "calc(50% - 8px) calc(50% - 8px)"
                : "calc(calc(100% - 32px) / 3) calc(calc(100% - 32px) / 3) calc(calc(100% - 32px) / 3)",
            gridAutoRows: mobile ? "256px" : "384px",
            gridGap: "16px",
          }}
        >
          <ThingsTile userProps={userProps} mobile={mobile} />
          <StorageTile userProps={userProps} mobile={mobile} />
          <ThroughputTile userProps={userProps} mobile={mobile} />
          <DiskTile userProps={userProps} mobile={mobile} />
        </div>
      </div>
    );
  }
}
