import React, { Component } from "react"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import Collapse from "@mui/material/Collapse"
import Skeleton from "@mui/material/Skeleton"
import Alert from "@mui/material/Alert"
import MoreVert from "@mui/icons-material/MoreVert"
import Add from "@mui/icons-material/Add"
import Edit from "@mui/icons-material/Edit"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline"
import moment from "moment"
import Moment from "react-moment"
import { withTranslation } from "react-i18next"
import {
  ErrorScreen,
  GenericDialog,
  debounce,
  countries,
  euroCountries,
  poundCountries,
} from "@igloocloud/igloosharedui"
import PaymentError from "./PaymentError"
import BillingAddress from "./BillingAddress"
import CompanyInfo from "./CompanyInfo"
import CreditCardInfo from "./CreditCardInfo"
import UpgradeAlert from "./UpgradeAlert"
import VAT from "./VAT"
import ClientContext from "../ClientContext"
import i18n from "../i18n"

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
  REACT_APP_ERROR_COLOR: errorColor,
  REACT_APP_LIGHT_COLOR: lightColor,
} = process.env

export default withTranslation()(
  class Payments extends Component {
    state = {
      alertOpen: false,
      throughputPickerOpen: false,
      storagePickerOpen: false,
      item: "",
      price: "",
      vatRate: null,
      anchorEl: null,
      target: null,
      addressOpen: false,
      creditCardOpen: false,
      companyOpen: false,
      vatOpen: false,
      removeItemOpen: false,
      loading: false,
      width: 0,
    }

    static contextType = ClientContext

    updateDimensions = () => {
      if (this.state.width !== window.innerWidth) {
        this.setState({ width: window.innerWidth })
      }
    }

    componentDidMount() {
      this.updateDimensions()
      window.addEventListener("resize", debounce(this.updateDimensions))
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions))
    }

    render() {
      const { mobile, userProps, t } = this.props
      const {
        anchorEl,
        target,
        addressOpen,
        creditCardOpen,
        companyOpen,
        vatOpen,
        removeItemOpen,
        loading,
        width,
      } = this.state
      const cellStyle = { height: "32px" }

      const subscriptionPrice = userProps.data
        ? userProps.data.billingPlan === "BUSINESS" &&
          userProps.data.businessPricing.price !== 0
          ? userProps.data
            ? userProps.data.businessPricing.price / 100
            : ""
          : 0
        : null
      const vatRate = userProps.data ? userProps.data.vatRate / 100 : null
      const vat = vatRate ? subscriptionPrice * vatRate : null
      const total = vat ? subscriptionPrice + vat : subscriptionPrice

      const currency = userProps.data
        ? euroCountries.includes(userProps.data.addressCountryOrTerritory)
          ? "€"
          : poundCountries.includes(userProps.data.addressCountryOrTerritory)
          ? "£"
          : "$"
        : ""

      const tableCellStyle = {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }

      const summaryLoaded =
        userProps.data && vatRate !== null && vatRate !== undefined

      const formatPrice = (price) =>
        price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      return (
        <>
          <div
            style={{
              padding: "16px",
              width: "calc(100% - 32px)",
              minHeight: "calc(100% - 32px)",
              backgroundColor,
              color: textOnBackgroundColor,
              maxWidth: "1200px",
              margin: "auto",
            }}
            className="notSelectable"
          >
            <PaymentError mobile={mobile} user={userProps.data} />
            {userProps.error ? (
              <ErrorScreen
                refetch={() => {
                  userProps.refetch()
                }}
                error={userProps.error}
              />
            ) : (
              <>
                <Typography variant="h5" style={{ paddingBottom: "16px" }}>
                  {t`Billing details`}
                </Typography>
                <Collapse
                  in={
                    userProps.data &&
                    userProps.data.billingPlan !== "BASIC" &&
                    userProps.data.billingStatus === "PAYED" &&
                    userProps.data.cardLast4Digits &&
                    userProps.data.cardExpiryYear &&
                    userProps.data.cardExpiryMonth &&
                    userProps.data.addressLine1 &&
                    userProps.data.addressCity &&
                    userProps.data.addressPostalCode &&
                    userProps.data.addressState &&
                    userProps.data.addressCountryOrTerritory &&
                    moment
                      .utc(userProps.data.nextBillingDate)
                      .diff(moment.utc(), "days") <= 14 &&
                    moment
                      .utc(userProps.data.nextBillingDate)
                      .diff(moment.utc(), "days") >= 0 &&
                    subscriptionPrice !== 0
                  }
                >
                  <Alert
                    severity="info"
                    icon={
                      <InfoOutlined
                        style={{
                          margin: "auto 12px auto 0",
                          color: lightColor,
                        }}
                      />
                    }
                  >
                    {userProps.data &&
                      (moment
                        .utc()
                        .diff(
                          moment.utc(userProps.data.nextBillingDate),
                          "days"
                        ) === 0 ? (
                        t`Your next payment is today`
                      ) : (
                        <Moment
                          fromNow
                          filter={(date) => t`Your next payment is ` + date}
                        >
                          {moment.utc(userProps.data.nextBillingDate)}
                        </Moment>
                      ))}
                  </Alert>
                  <div style={{ height: "16px" }} />
                </Collapse>
                <Paper
                  style={{
                    backgroundColor,
                  }}
                  elevation={4}
                >
                  <Table
                    className="notSelectable"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "35%" : "25%",
                          fontWeight: "bold",
                        }}
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          t`Credit card`
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.cardLast4Digits ? (
                            width > 450 ? (
                              "•••• •••• •••• " + userProps.data.cardLast4Digits
                            ) : (
                              "•••• " + userProps.data.cardLast4Digits
                            )
                          ) : (
                            <em>{t`Required`}</em>
                          )
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "48px",
                          margin: "auto",
                          padding: "0 8px 0 0",
                        }}
                        align="right"
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.cardLast4Digits ? (
                            userProps.data.billingPlan === "BASIC" ||
                            (userProps.data.billingPlan === "BUSINESS" &&
                              !userProps.data.businessPricing.price) ? (
                              <IconButton
                                onClick={(event) =>
                                  this.setState({
                                    anchorEl: event.currentTarget,
                                    target: "creditCard",
                                  })
                                }
                                size="large"
                              >
                                <MoreVert />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  this.setState({
                                    creditCardOpen: true,
                                    anchorEl: null,
                                  })
                                }
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                            )
                          ) : (
                            <IconButton
                              onClick={() =>
                                this.setState({ creditCardOpen: true })
                              }
                              size="large"
                            >
                              <Add />
                            </IconButton>
                          )
                        ) : (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "35%" : "25%",
                          fontWeight: "bold",
                        }}
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          t`Address`
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {userProps.data ? (
                          userProps.data.addressLine1 &&
                          userProps.data.addressPostalCode &&
                          userProps.data.addressCity &&
                          userProps.data.addressState &&
                          userProps.data.addressCountryOrTerritory ? (
                            `${userProps.data.addressLine1}, ${
                              userProps.data.addressCity
                            }, ${
                              i18n.language === "en"
                                ? countries.find(
                                    (country) =>
                                      country.code ===
                                      userProps.data.addressCountryOrTerritory
                                  ).en
                                : countries.find(
                                    (country) =>
                                      country.code ===
                                      userProps.data.addressCountryOrTerritory
                                  ).it
                            }`
                          ) : (
                            <em>{t`Required`}</em>
                          )
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "48px",
                          margin: "auto",
                          padding: "0 8px 0 0",
                        }}
                        align="right"
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.addressLine1 &&
                          userProps.data.addressPostalCode &&
                          userProps.data.addressCity &&
                          userProps.data.addressState &&
                          userProps.data.addressCountryOrTerritory ? (
                            userProps.data.billingPlan === "BASIC" ||
                            (userProps.data.billingPlan === "BUSINESS" &&
                              !userProps.data.price) ? (
                              <IconButton
                                onClick={(event) =>
                                  this.setState({
                                    anchorEl: event.currentTarget,
                                    target: "address",
                                  })
                                }
                                size="large"
                              >
                                <MoreVert />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={(event) =>
                                  this.setState({
                                    addressOpen: true,
                                    anchorEl: null,
                                  })
                                }
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                            )
                          ) : (
                            <IconButton
                              onClick={() =>
                                this.setState({ addressOpen: true })
                              }
                              size="large"
                            >
                              <Add />
                            </IconButton>
                          )
                        ) : (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "35%" : "25%",
                          fontWeight: "bold",
                        }}
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          t`Company`
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.companyName
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "48px",
                          margin: "auto",
                          padding: "0 8px 0 0",
                        }}
                        align="right"
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.companyName ? (
                            <IconButton
                              onClick={(event) =>
                                this.setState({
                                  anchorEl: event.currentTarget,
                                  target: "company",
                                })
                              }
                              size="large"
                            >
                              <MoreVert />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() =>
                                this.setState({ companyOpen: true })
                              }
                              size="large"
                            >
                              <Add />
                            </IconButton>
                          )
                        ) : (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: mobile ? "35%" : "25%",
                          fontWeight: "bold",
                        }}
                      >
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          t`VAT number`
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {userProps.data &&
                        vatRate !== null &&
                        vatRate !== undefined ? (
                          userProps.data.vatNumber
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "48px",
                          margin: "auto",
                          padding: "0 8px 0 0",
                        }}
                        align="right"
                      >
                        {userProps.data ? (
                          userProps.data.vatNumber &&
                          vatRate !== null &&
                          vatRate !== undefined ? (
                            <IconButton
                              onClick={(event) =>
                                this.setState({
                                  anchorEl: event.currentTarget,
                                  target: "vat",
                                })
                              }
                              size="large"
                            >
                              <MoreVert />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => this.setState({ vatOpen: true })}
                              size="large"
                            >
                              <Add />
                            </IconButton>
                          )
                        ) : (
                          <Skeleton
                            variant="circular"
                            style={{
                              width: "24px",
                              height: "24px",
                              margin: "auto",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Paper>
                <Typography variant="h5" style={{ padding: "32px 0 16px 0" }}>
                  {t`Your bill`}
                </Typography>
                <UpgradeAlert
                  visible={userProps.data?.billingPlan === "BASIC"}
                  mobile={mobile}
                />
                <Paper
                  style={{
                    backgroundColor,
                  }}
                  elevation={4}
                >
                  <Table className="notSelectable">
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyle,
                          width: summaryLoaded ? "33.33%" : "calc(100% - 64px)",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        component="th"
                        scope="row"
                        colSpan={2}
                      >
                        {summaryLoaded ? (
                          "Igloo " +
                          userProps.data.billingPlan[0] +
                          userProps.data.billingPlan.slice(1).toLowerCase()
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          ...cellStyle,
                          width: summaryLoaded ? "33.34%" : "64px",
                        }}
                      >
                        {summaryLoaded ? (
                          currency + formatPrice(subscriptionPrice.toFixed(2))
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={cellStyle} colSpan={2}>
                        {summaryLoaded ? (
                          t`VAT` + ` (${userProps.data.vatRate}%)`
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {summaryLoaded ? (
                          currency + formatPrice(vat.toFixed(2))
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ ...cellStyle, fontWeight: "bold" }}
                        colSpan={2}
                      >
                        {summaryLoaded ? t`Total` : <Skeleton variant="text" />}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        {summaryLoaded ? (
                          currency + formatPrice(total.toFixed(2))
                        ) : (
                          <Skeleton variant="text" />
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Paper>
              </>
            )}
          </div>
          <Menu
            id="manage-credit-card-menu"
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={() => this.setState({ anchorEl: null })}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{ style: { backgroundColor } }}
          >
            <MenuItem
              onClick={() =>
                this.setState(
                  target === "creditCard"
                    ? { creditCardOpen: true, anchorEl: null }
                    : target === "address"
                    ? { addressOpen: true, anchorEl: null }
                    : target === "company"
                    ? { companyOpen: true, anchorEl: null }
                    : { vatOpen: true, anchorEl: null }
                )
              }
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText
                primary={t`Edit`}
                disableTypography
                style={{ color: textOnBackgroundColor }}
              />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ removeItemOpen: true, anchorEl: null })
              }
            >
              <ListItemIcon>
                <RemoveCircleOutline style={{ color: errorColor }} />
              </ListItemIcon>
              <ListItemText
                primary={t`Remove`}
                disableTypography
                style={{ color: errorColor }}
              />
            </MenuItem>
          </Menu>
          <GenericDialog
            title={t(
              target === "creditCard"
                ? t`Remove your credit card`
                : target === "address"
                ? t`Remove your address`
                : target === "company"
                ? t`Remove your company info`
                : t`Remove your VAT number`
            )}
            open={removeItemOpen}
            close={() =>
              this.setState({
                removeItemOpen: false,
              })
            }
            textButton={t`Close`}
            textButtonFunction={() =>
              this.setState({
                removeItemOpen: false,
              })
            }
            isContainedButtonRed
            containedButton={t`Remove`}
            containedButtonLoading={loading}
            containedButtonFunction={async () => {
              try {
                this.setState({ loading: true })

                if (target === "creditCard") {
                  await this.context.mutation.updatePaymentMethod({
                    stripePaymentMethod: null,
                  })
                }

                if (target === "address") {
                  await this.context.mutation.updateUser({
                    addressLine1: null,
                    addressLine2: null,
                    addressCity: null,
                    addressPostalCode: null,
                    addressState: null,
                    addressCountryOrTerritory: null,
                  })
                }

                if (target === "company") {
                  await this.context.mutation.updateUser({ companyName: null })
                }

                if (target === "vat") {
                  await this.context.mutation.updateUser({ vatNumber: null })
                }

                this.setState({ removeItemOpen: false })
              } finally {
                this.setState({ loading: false })
              }
            }}
          >
            {t(
              target === "creditCard"
                ? t`Are you sure you want to remove your credit card from this account?`
                : target === "address"
                ? t`Are you sure you want to remove your address from this account?`
                : target === "company"
                ? t`Are you sure you want to remove your company info from this account?`
                : t`Are you sure you want to remove your VAT number from this account?`
            )}
          </GenericDialog>
          {userProps.data && (
            <>
              <BillingAddress
                open={addressOpen}
                close={() => this.setState({ addressOpen: false })}
                user={userProps.data}
              />
              <CreditCardInfo
                open={creditCardOpen}
                close={() => this.setState({ creditCardOpen: false })}
                user={userProps.data}
              />
              <CompanyInfo
                open={companyOpen}
                close={() => this.setState({ companyOpen: false })}
                user={userProps.data}
              />
              <VAT
                open={vatOpen}
                close={() => this.setState({ vatOpen: false })}
                user={userProps.data}
              />
            </>
          )}
        </>
      )
    }
  }
)
