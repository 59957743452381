import React, { Component } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { addPropsToChild } from "../utilities"
import { debounce } from "@igloocloud/igloosharedui"

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
} = process.env

export default class HomeTile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      smallTile: false,
    }
  }

  updateDimensions = () => {
    const { smallTile } = this.state

    if (window.innerWidth < 416) {
      !smallTile && this.setState({ smallTile: true })
    } else {
      smallTile && this.setState({ smallTile: false })
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", debounce(this.updateDimensions))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", debounce(this.updateDimensions))
  }

  render() {
    const { children, title, style, mobile, iconButton } = this.props
    const { smallTile } = this.state

    return (
      <Paper
        elevation={3}
        style={{
          backgroundColor,
          height: "100%",
          ...style,
        }}
      >
        <div
          style={{
            height: "64px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant={mobile ? "h6" : "h5"}
            style={{
              lineHeight: "64px",
              marginLeft: "16px",
              fontWeight: 500,
              color: textOnBackgroundColor,
              width: iconButton ? "calc(100% - 72px)" : "100%",
            }}
            className="notSelectable"
          >
            {title}
          </Typography>
        </div>
        <div style={{ height: "calc(100% - 64px)" }}>
          {addPropsToChild(children, { smallTile })}
        </div>
      </Paper>
    )
  }
}
