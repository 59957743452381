import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { StripeProvider, Elements } from "react-stripe-elements";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      {navigator.onLine ? (
        <StripeProvider apiKey="pk_live_xfYIFf4WVIvOLB370gsVtXGF00nlTRu4rF">
          <Elements>
            <App />
          </Elements>
        </StripeProvider>
      ) : (
        <App />
      )}
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);
