import React, { Component } from "react";
import queryStringify from "querystringify";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { GenericDialog } from "@igloocloud/igloosharedui";
import ClientContext from "../ClientContext";

export default withTranslation()(
  class DeleteThingDialog extends Component {
    constructor(props) {
      super(props);

      this.state = {
        deleteThingOpen: false,
        reset: false,
        deleteThingLoading: false,
        redirect: "",
      };
    }

    static contextType = ClientContext;

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({ reset: false });
      }
    }

    render() {
      const { open, close, thingId, t } = this.props;
      const { deleteThingLoading, redirect } = this.state;

      if (redirect) {
        const tempRedirect = redirect;

        this.setState({ redirect: "" });

        return <Redirect to={tempRedirect} />;
      }

      return (
        <GenericDialog
          open={open}
          close={close}
          title={t`Delete thing`}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={t`Delete`}
          containedButtonFunction={() => {
            this.setState({ deleteThingLoading: true });

            this.context.mutation
              .deleteThing({ id: thingId })
              .then(() => {
                this.setState({
                  deleteThingOpen: false,
                  redirect:
                    queryStringify.parse(window.location.search).id === thingId
                      ? "/things"
                      : "",
                });
                close();
              })
              .finally(() => this.setState({ deleteThingLoading: false }));
          }}
          containedButtonLoading={deleteThingLoading}
          containedButtonDisabled={deleteThingLoading}
          isContainedButtonRed
        >
          {t`Be careful, this thing will be deleted permanently` + "."}
        </GenericDialog>
      );
    }
  }
);
