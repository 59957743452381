import React, { Component } from "react"
import { createTheme, adaptV4Theme } from "@mui/material/styles"
import ThemeProvider from "@mui/styles/ThemeProvider"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import Clear from "@mui/icons-material/Clear"
import { withTranslation } from "react-i18next"
import { GenericDialog, countries, AQFlag } from "@igloocloud/igloosharedui"
import { ListItemIcon, ListItemText } from "@mui/material"
import { CircleFlag } from "react-circle-flags"
import ClientContext from "../ClientContext"
import i18n from "../i18n"

const {
  REACT_APP_MAIN_COLOR: mainColor,
  REACT_APP_LIGHT_COLOR: lightColor,
  REACT_APP_SECONDARY_COLOR: secondaryColor,
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
  REACT_APP_ERROR_COLOR: errorColor,
} = process.env

export default withTranslation()(
  class BillingSettings extends Component {
    constructor(props) {
      super(props)

      this.state = {
        addressLine1: props.user.addressLine1 || "",
        addressLine1Empty: false,
        addressLine2: props.user.addressLine2 || "",
        addressCity: props.user.addressCity || "",
        addressCityEmpty: false,
        addressPostalCode: props.user.addressPostalCode || "",
        addressPostalCodeEmpty: false,
        addressState: props.user.addressState || "",
        addressStateEmpty: false,
        addressCountryOrTerritory: props.user.addressCountryOrTerritory || "",
        loading: false,
      }
    }

    static contextType = ClientContext

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({
          addressLine1: nextProps.user.addressLine1 || "",
          addressLine1Empty: false,
          addressLine2: nextProps.user.addressLine2 || "",
          addressCity: nextProps.user.addressCity || "",
          addressCityEmpty: false,
          addressPostalCode: nextProps.user.addressPostalCode || "",
          addressPostalCodeEmpty: false,
          addressState: nextProps.user.addressState || "",
          addressStateEmpty: false,
          addressCountryOrTerritory:
            nextProps.user.addressCountryOrTerritory || "",
        })
      }
    }

    render() {
      const { open, close, t } = this.props
      const {
        addressLine1,
        addressLine1Empty,
        addressLine2,
        addressCity,
        addressCityEmpty,
        addressPostalCode,
        addressPostalCodeEmpty,
        addressState,
        addressStateEmpty,
        addressCountryOrTerritory,
        loading,
      } = this.state

      return (
        <GenericDialog
          title={t`Enter your billing address`}
          open={open}
          close={close}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={t`Confirm`}
          containedButtonFunction={() => {
            this.setState({ loading: true })

            this.context.mutation
              .updateUser({
                addressLine1,
                addressLine2,
                addressCity,
                addressPostalCode,
                addressState,
                addressCountryOrTerritory,
              })
              .finally(() => {
                this.setState({ loading: false })

                close()
              })
          }}
          containedButtonDisabled={
            !addressLine1.replace(/\s/g, "").length ||
            addressLine1.length >= 256 ||
            addressLine2.length >= 256 ||
            !addressCity.replace(/\s/g, "").length ||
            addressCity.length >= 256 ||
            !addressPostalCode.replace(/\s/g, "").length ||
            addressPostalCode.length >= 256 ||
            !addressState.replace(/\s/g, "").length ||
            addressState.length >= 256 ||
            !addressCountryOrTerritory.replace(/\s/g, "").length ||
            addressCountryOrTerritory.length >= 256 ||
            loading
          }
          containedButtonLoading={loading}
        >
          {({ fullScreen }) => (
            <>
              <TextField
                required
                id="address-line-one"
                label={t`Address line 1`}
                placeholder={t`Street and number`}
                value={addressLine1}
                variant="outlined"
                error={addressLine1Empty || addressLine1.length >= 256}
                helperText={
                  addressLine1.length >= 256
                    ? t`Too long!`
                    : addressLine1Empty
                    ? t`This field is required`
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    addressLine1: event.target.value,
                    addressLine1Empty:
                      event.target.value.replace(/\s/g, "") === "",
                  })
                }
                style={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                InputLabelProps={addressLine1 ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: addressLine1 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            addressLine1: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "address-line1",
                }}
              />
              <TextField
                id="address-line-two"
                label={t`Address line 2`}
                placeholder={
                  t`Apartment` +
                  ", " +
                  t`building` +
                  ", " +
                  t`floor` +
                  ", " +
                  t`etc` +
                  "."
                }
                value={addressLine2}
                variant="outlined"
                error={addressLine2.length >= 256}
                helperText={addressLine2.length >= 256 ? t`Too long!` : " "}
                onChange={(event) =>
                  this.setState({
                    addressLine2: event.target.value,
                  })
                }
                style={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                InputLabelProps={addressLine2 ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: addressLine2 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            addressLine2: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "address-line2",
                }}
              />
              <TextField
                required
                id="city"
                label={t`City`}
                value={addressCity}
                variant="outlined"
                error={addressCityEmpty || addressCity.length >= 256}
                helperText={
                  addressCity.length >= 256
                    ? t`Too long!`
                    : addressCityEmpty
                    ? t`This field is required`
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    addressCity: event.target.value,
                    addressCityEmpty:
                      event.target.value.replace(/\s/g, "") === "",
                  })
                }
                style={{
                  width: fullScreen ? "100%" : "calc(60% - 8px)",
                  marginRight: fullScreen ? 0 : "16px",
                  marginBottom: "16px",
                }}
                InputLabelProps={addressCity ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: addressCity && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            addressCity: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "address-level2",
                }}
              />
              <TextField
                required
                id="postal-code"
                label={t`Postal code`}
                value={addressPostalCode}
                variant="outlined"
                error={
                  addressPostalCodeEmpty || addressPostalCode.length >= 256
                }
                helperText={
                  addressPostalCode.length >= 256
                    ? t`Too long!`
                    : addressPostalCodeEmpty
                    ? t`This field is required`
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    addressPostalCode: event.target.value,
                    addressPostalCodeEmpty:
                      event.target.value.replace(/\s/g, "") === "",
                  })
                }
                style={{
                  width: fullScreen ? "100%" : "calc(40% - 8px)",
                  marginBottom: "16px",
                }}
                InputLabelProps={addressPostalCode ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: addressPostalCode && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            addressPostalCode: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "postal-code",
                }}
                type="number"
              />
              <TextField
                required
                id="state"
                label={t`State`}
                value={addressState}
                variant="outlined"
                error={addressStateEmpty || addressState.length >= 256}
                helperText={
                  addressPostalCode.length >= 256
                    ? t`Too long!`
                    : addressStateEmpty
                    ? t`This field is required`
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    addressState: event.target.value,
                    addressStateEmpty:
                      event.target.value.replace(/\s/g, "") === "",
                  })
                }
                style={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                InputLabelProps={addressState ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: addressState && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            addressState: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "address-level1",
                }}
              />
              <StyledEngineProvider injectFirst>
                <ThemeProvider
                  theme={createTheme(
                    adaptV4Theme({
                      palette: {
                        default: { main: backgroundColor },
                        primary: { light: lightColor, main: mainColor },
                        secondary: { main: secondaryColor },
                        error: { main: errorColor },
                      },
                      overrides: {
                        MuiList: {
                          root: {
                            backgroundColor,
                          },
                        },
                        MuiMenu: {
                          list: {
                            padding: 0,
                          },
                        },
                        MuiSelect: {
                          select: { color: textOnBackgroundColor },
                          icon: { color: textOnBackgroundColor },
                        },
                      },
                    })
                  )}
                >
                  <TextField
                    required
                    label={t`Country or territory`}
                    value={addressCountryOrTerritory}
                    onChange={(event) => {
                      this.setState({
                        addressCountryOrTerritory: event.target.value,
                      })
                    }}
                    variant="outlined"
                    select
                    SelectProps={{
                      renderValue: (value) => {
                        const { [i18n.language]: translatedName } =
                          countries.find((country) => country.code === value)

                        return translatedName
                      },
                    }}
                    style={{
                      width: "100%",
                    }}
                    InputLabelProps={
                      addressCountryOrTerritory ? { shrink: true } : {}
                    }
                    helperText=" "
                  >
                    {countries
                      .sort((a, b) =>
                        a[i18n.language] > b[i18n.language]
                          ? 1
                          : a[i18n.language] < b[i18n.language]
                          ? -1
                          : 0
                      )
                      .map(
                        ({
                          code,
                          flagCode,
                          [i18n.language]: translatedName,
                        }) => (
                          <MenuItem
                            style={{ color: textOnBackgroundColor }}
                            value={code}
                            key={"billing-address-country-or-territory-" + code}
                          >
                            <ListItemIcon>
                              {code === "AQ" ? (
                                <AQFlag />
                              ) : (
                                <CircleFlag
                                  countryCode={(flagCode || code).toLowerCase()}
                                  height="24"
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText style={{ marginRight: "8px" }}>
                              {translatedName}
                            </ListItemText>
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </ThemeProvider>
              </StyledEngineProvider>
            </>
          )}
        </GenericDialog>
      )
    }
  }
)
