import React, { useState, useContext } from "react"
import { injectStripe } from "react-stripe-elements"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import Alert from "@mui/material/Alert"
import Refresh from "@mui/icons-material/Refresh"
import ErrorOutline from "@mui/icons-material/ErrorOutline"
import LockOpen from "@mui/icons-material/LockOpen"
import { withTranslation } from "react-i18next"
import ClientContext from "../ClientContext"
import { CenteredSpinner } from "@igloocloud/igloosharedui"

export default withTranslation()(
  injectStripe(({ user, mobile, stripe, t }) => {
    const [loading, setLoading] = useState(false)
    const client = useContext(ClientContext)

    const authenticate = () => {
      setLoading(true)

      stripe.retrievePaymentIntent(user.paymentIntentSecret).then(
        ({ error, paymentIntent }) =>
          !error &&
          stripe
            .confirmCardPayment(paymentIntent.client_secret)
            .then(() => client.mutation.confirmPaymentExecution())
            .finally(() => setLoading(false))
      )
    }

    const retry = () => {
      setLoading(true)

      client.mutation.retryPayment().finally(() => setLoading(false))
    }

    return (
      <Collapse in={user && user.billingStatus !== "PAYED"}>
        {user && (
          <Alert
            severity="error"
            action={
              loading ? (
                <CenteredSpinner
                  color="#f44336"
                  size="sm"
                  style={{ height: "24px", paddingRight: "5px" }}
                />
              ) : mobile ? (
                user.billingStatus === "REQUIRES_AUTHENTICATION" ? (
                  <IconButton color="inherit" onClick={authenticate} size="large">
                    <LockOpen />
                  </IconButton>
                ) : (
                  <IconButton color="inherit" onClick={retry} size="large">
                    <Refresh />
                  </IconButton>
                )
              ) : user.billingStatus === "REQUIRES_AUTHENTICATION" ? (
                <Button color="inherit" onClick={authenticate}>
                  {t`Authenticate`}
                </Button>
              ) : (
                <Button color="inherit" onClick={retry}>
                  {t`Retry`}
                </Button>
              )
            }
            icon={<ErrorOutline style={{ margin: "auto 12px auto 0" }} />}
          >
            {user.billingStatus &&
              (user.billingStatus === "FAILED_PAYMENT"
                ? t`Your last payment attempt failed`
                : user.billingStatus === "INSUFFICIENT_FUNDS"
                ? t`You didn't have enough funds to pay your last bill`
                : t`You'll need to authenticate to pay your last bill`)}
          </Alert>
        )}
        <div style={{ height: "16px" }} />
      </Collapse>
    );
  })
)
