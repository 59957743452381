import React, { useState } from "react"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import SvgIcon from "@mui/material/SvgIcon"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { withTranslation } from "react-i18next"

export default withTranslation()(({ visible, mobile, t }) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)
  const openUpgradeDialog = () => setUpgradeDialogOpen(true)
  const closeUpgradeDialog = () => setUpgradeDialogOpen(false)

  return (
    <>
      <Collapse in={visible}>
        <Alert
          severity="info"
          icon={false}
          action={
            mobile ? (
              <IconButton
                color="inherit"
                onClick={openUpgradeDialog}
                size="large"
              >
                <SvgIcon>
                  <svg
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M13.13 22.19L11.5 18.36C13.07 17.78 14.54 17 15.9 16.09L13.13 22.19M5.64 12.5L1.81 10.87L7.91 8.1C7 9.46 6.22 10.93 5.64 12.5M19.22 4C19.5 4 19.75 4 19.96 4.05C20.13 5.44 19.94 8.3 16.66 11.58C14.96 13.29 12.93 14.6 10.65 15.47L8.5 13.37C9.42 11.06 10.73 9.03 12.42 7.34C15.18 4.58 17.64 4 19.22 4M19.22 2C17.24 2 14.24 2.69 11 5.93C8.81 8.12 7.5 10.53 6.65 12.64C6.37 13.39 6.56 14.21 7.11 14.77L9.24 16.89C9.62 17.27 10.13 17.5 10.66 17.5C10.89 17.5 11.13 17.44 11.36 17.35C13.5 16.53 15.88 15.19 18.07 13C23.73 7.34 21.61 2.39 21.61 2.39S20.7 2 19.22 2M14.54 9.46C13.76 8.68 13.76 7.41 14.54 6.63S16.59 5.85 17.37 6.63C18.14 7.41 18.15 8.68 17.37 9.46C16.59 10.24 15.32 10.24 14.54 9.46M8.88 16.53L7.47 15.12L8.88 16.53M6.24 22L9.88 18.36C9.54 18.27 9.21 18.12 8.91 17.91L4.83 22H6.24M2 22H3.41L8.18 17.24L6.76 15.83L2 20.59V22M2 19.17L6.09 15.09C5.88 14.79 5.73 14.47 5.64 14.12L2 17.76V19.17Z"
                    />
                  </svg>
                </SvgIcon>
              </IconButton>
            ) : (
              <Button color="inherit" onClick={openUpgradeDialog}>
                {t`Upgrade`}
              </Button>
            )
          }
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {t`Need more? Upgrade to Igloo Business`}
        </Alert>
        <div style={{ height: "16px" }} />
      </Collapse>
      <GenericDialog
        title={t`Upgrade to Igloo Business`}
        open={upgradeDialogOpen}
        close={closeUpgradeDialog}
        textButton={t`Close`}
        textButtonFunction={closeUpgradeDialog}
        containedButton={t`Upgrade`}
        containedButtonFunction={() => {
          closeUpgradeDialog()
        }}
        containedButtonProps={{
          component: "a",
          href:
            "mailto:sales@igloo.ooo?subject=" +
            t`Upgrading to Igloo Business` +
            "&body=" +
            t`Please describe your project and we'll reply with an offer tailored to your needs` +
            ".",
        }}
      >
        {t`Thank you for considering upgrading to Igloo Business!`} <br />
        <br />
        {t`By clicking "Upgrade" you'll be able to get in touch with our sales team and get an offer tailored to your needs` +
          "."}
      </GenericDialog>
    </>
  )
})
