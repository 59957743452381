import React from "react"
import tinyColor from "tinycolor2"
import HomeTile from "./HomeTile"
import Typography from "@mui/material/Typography"
import JSBI from "jsbi"
import {
  CenteredSpinner,
  ErrorScreen,
  formatBytes,
  addDotBigInt,
} from "@igloocloud/igloosharedui"
import { withTranslation } from "react-i18next"

const {
  REACT_APP_LIGHT_COLOR: lightColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
  REACT_APP_SECONDARY_BACKGROUND_COLOR: secondaryBackgroundColor,
  REACT_APP_ERROR_COLOR: errorColor,
} = process.env

export default withTranslation()(
  ({ userProps: { error, loading, data, refetch }, mobile, t }) => (
    <HomeTile title={t`Disk blocks`} mobile={mobile}>
      {({ smallTile }) => (
        <div
          style={{
            height: "100%",
            width: "calc(100% - 64px)",
            padding: "0 32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="notSelectable"
        >
          {error && <ErrorScreen refetch={refetch} error={error} />}
          {loading && <CenteredSpinner size={!smallTile ? "lg" : "md"} />}
          {data &&
            (!localStorage.getItem("server") ||
            localStorage.getItem("server").includes(".igloo.ooo") ? (
              <div style={{ width: "100%" }}>
                <Typography
                  variant={smallTile ? "h3" : "h2"}
                  component="font"
                  style={{ color: textOnBackgroundColor }}
                >
                  {parseFloat(
                    addDotBigInt(formatBytes(data.usedDiskSpace * 10).bytes, 1)
                  ) + formatBytes((data.usedDiskSpace * 10).toString()).unit}
                  <Typography
                    variant={smallTile ? "h5" : "h4"}
                    component="font"
                    style={{
                      color: tinyColor(textOnBackgroundColor)
                        .setAlpha(0.72)
                        .toRgbString(),
                    }}
                  >
                    {"/" +
                      parseFloat(
                        addDotBigInt(
                          JSBI.multiply(
                            formatBytes(data.maxDiskSpace).bytes,
                            JSBI.BigInt(10)
                          ),
                          1
                        )
                      ) +
                      formatBytes(data.maxDiskSpace.toString()).unit}
                  </Typography>
                </Typography>
                <div
                  style={{
                    backgroundColor: secondaryBackgroundColor,
                    height: "24px",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "32px 0",
                  }}
                >
                  {data.usedDiskSpace !== 0 && (
                    <div
                      style={{
                        backgroundColor:
                          data.usedDiskSpace / data.maxDiskSpace > 0.9
                            ? errorColor
                            : lightColor,
                        height: "24px",
                        borderRadius: "12px",
                        width:
                          (
                            (data.usedDiskSpace * 100) /
                            data.maxDiskSpace
                          ).toString() + "%",
                        minWidth: "24px",
                        maxWidth: JSBI.greaterThanOrEqual(
                          JSBI.BigInt(data.usedDiskSpace),
                          JSBI.BigInt(data.maxDiskSpace)
                        )
                          ? "100%"
                          : "calc(100% - 12px)",
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <Typography
                variant="h2"
                component="font"
                style={{
                  color: textOnBackgroundColor,
                  fontSize: smallTile ? "3.5rem" : "4.5rem",
                }}
              >
                {parseFloat(
                  addDotBigInt(formatBytes(data.usedDiskSpace * 10).bytes, 1)
                ) + formatBytes(data.usedDiskSpace.toString()).unit}
              </Typography>
            ))}
        </div>
      )}
    </HomeTile>
  )
)
