import React, { Component } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { GenericDialog } from "@igloocloud/igloosharedui";
import { withTranslation } from "react-i18next";
import StripeElementWrapper from "./stripe/StripeElementWrapper";
import ClientContext from "../ClientContext";

export default withTranslation()(
  injectStripe(
    class PaymentInfoDialog extends Component {
      state = {
        numberComplete: false,
        expiryComplete: false,
        cvcComplete: false,
        numberError: false,
        expiryError: false,
        cvcError: false,
        anchorEl: null,
        loading: false,
      };

      static contextType = ClientContext;

      componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open && nextProps.open) {
          this.setState({
            loading: false,
            numberComplete: false,
            expiryComplete: false,
            cvcComplete: false,
            numberError: false,
            expiryError: false,
            cvcError: false,
          });
        }
      }

      render() {
        const { open, close, stripe, elements, t } = this.props;
        const {
          numberComplete,
          expiryComplete,
          cvcComplete,
          stripeError,
          numberError,
          expiryError,
          cvcError,
          loading,
        } = this.state;

        const setupPayment = async () => {
          this.setState({ loading: true });

          this.context.mutation
            .initiateBillingSetup()
            .then((secret) =>
              stripe
                .handleCardSetup(secret, elements.getElement("cardNumber"))
                .then(({ error, setupIntent }) => {
                  if (error) {
                    this.setState({
                      stripeError:
                        error.code === "card_declined"
                          ? t`Your card was declined`
                          : t`Error`,
                    });

                    throw new Error("Error"); //Do not remove: allows react-stripe-elements to display the error below the textfield
                  } else {
                    this.context.mutation.updatePaymentMethod({
                      stripePaymentMethod: setupIntent.payment_method,
                    });
                  }
                })
            )
            .then(close)
            .finally(() => this.setState({ loading: false }))
            .catch(() => {}); //Do not remove: allows react-stripe-elements to display the error below the textfield
        };

        return (
          <GenericDialog
            title={t`Enter your card details`}
            open={open}
            close={close}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Confirm`}
            containedButtonFunction={setupPayment}
            containedButtonDisabled={
              !numberComplete ||
              !expiryComplete ||
              !cvcComplete ||
              numberError ||
              expiryError ||
              cvcError ||
              !elements.getElement("cardNumber") ||
              loading
            }
            containedButtonLoading={loading}
          >
            <StripeElementWrapper
              label={t`Number` + " *"}
              component={CardNumberElement}
              setComplete={(numberComplete) =>
                this.setState({ numberComplete })
              }
              setError={(numberError) => this.setState({ numberError })}
              stripeError={stripeError}
            />
            <StripeElementWrapper
              label={t`Expiry date` + " *"}
              component={CardExpiryElement}
              setComplete={(expiryComplete) =>
                this.setState({ expiryComplete })
              }
              setError={(expiryError) => this.setState({ expiryError })}
            />
            <StripeElementWrapper
              label={t`CVC` + " *"}
              component={CardCVCElement}
              setComplete={(cvcComplete) => this.setState({ cvcComplete })}
              setError={(cvcError) => this.setState({ cvcError })}
              isLastElement
            />
          </GenericDialog>
        );
      }
    }
  )
);
