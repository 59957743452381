import React, { Component, useState, useEffect, useContext } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import HomeIcon from "@mui/icons-material/Home"
import DeveloperBoard from "@mui/icons-material/DeveloperBoard"
import AttachMoney from "@mui/icons-material/AttachMoney"
import Sidebar from "./Sidebar"
import Home from "./home/Home"
import Payments from "./payments/Payments"
import ThingMain from "./things/ThingMain"
import ThingBody from "./things/ThingBody"
import tinyColor from "tinycolor2"
import { Switch, Route, Link, Redirect, useLocation } from "react-router-dom"
import SwipeableRoutes from "react-swipeable-routes"
import Helmet from "react-helmet"
import { withTranslation } from "react-i18next"
import {
  SettingsDialog,
  EmailNotVerified,
  AccountPopover,
  PolicyDialog,
} from "@igloocloud/igloosharedui"
import Igloo, { User, DeveloperThings, Metadata } from "@igloocloud/react-igloo"
import logo from "./assets/logo.svg"
import ClientContext from "./ClientContext"

const useQuery = () => new URLSearchParams(useLocation().search)

//needed because redirect logic doesn't work in functional component
class AccountPopoverWrapper extends Component {
  state = {
    redirect: "",
  }

  render() {
    const { openSettings, user, logOut, changeAccount, isOpen, forceUpdate } =
      this.props
    const { redirect } = this.state

    if (redirect) {
      let tempRedirect = redirect
      this.setState({ redirect: "" })

      return (
        <>
          <Redirect push to={tempRedirect} />
          {forceUpdate()}
        </>
      )
    }

    return (
      <AccountPopover
        buttonStyle={{
          margin: "12px 12px 12px auto",
        }}
        setOpen={openSettings}
        user={user}
        setRedirect={(redirect) => this.setState({ redirect })}
        logOut={logOut}
        changeAccount={changeAccount}
        isOpen={isOpen}
      />
    )
  }
}

class PolicyDialogWrapper extends Component {
  state = { acceptPrivacyPolicyOpen: false }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.data &&
      nextProps.data &&
      nextProps.metadata &&
      nextProps.data.privacyPolicyAccepted !==
        nextProps.metadata.privacyPolicyVersion
    ) {
      this.setState({ acceptPrivacyPolicyOpen: true })
    }
  }

  render() {
    const { data } = this.props
    const { acceptPrivacyPolicyOpen } = this.state

    return data ? (
      <PolicyDialog
        requireConsent
        open={acceptPrivacyPolicyOpen}
        close={() => this.setState({ acceptPrivacyPolicyOpen: false })}
        user={data}
      />
    ) : null
  }
}

export default withTranslation()(
  ({
    logOut,
    changeAccount,
    isOpen,
    forceUpdate,
    greaterThan1200,
    greaterThan1600,
    bearer,
    mobile,
    t,
  }) => {
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(true)
    const [redirect, setRedirect] = useState("")
    const [sortBy, setSortBy] = useState(
      JSON.parse(localStorage.getItem("sortingPreferences")).find(
        ({ id }) => id === localStorage.getItem("userId")
      )?.sortBy || "type"
    )
    const [sortDirection, setSortDirection] = useState(
      JSON.parse(localStorage.getItem("sortingPreferences")).find(
        ({ id }) => id === localStorage.getItem("userId")
      )?.sortDirection || "ASCENDING"
    )
    const query = useQuery()
    const { mutation } = useContext(ClientContext)

    useEffect(
      () => {
        mutation.updateUser({
          language:
            localStorage.getItem("language") ||
            (navigator.language || navigator.userLanguage).slice(0, 2),
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    if (redirect) {
      let tempRedirect = redirect
      setRedirect("")

      return (
        <>
          <Redirect push to={tempRedirect} />
          {forceUpdate()}
        </>
      )
    }

    const {
      REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
      REACT_APP_NAME: appName,
      REACT_APP_MAIN_COLOR: mainColor,
    } = process.env

    const desktopRoutes = (userProps, developerThingsProps, firmwareProps) => (
      <Switch>
        <Route exact path="/">
          <Home userProps={userProps} />
        </Route>
        <Route path="/things">
          <ThingMain
            userProps={userProps}
            firmwareProps={firmwareProps}
            developerThingsProps={developerThingsProps}
            logOut={logOut}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
        </Route>
        {(!localStorage.getItem("server") ||
          localStorage.getItem("server").includes(".igloo.ooo")) && (
          <Route exact path="/payments">
            <Payments userProps={userProps} />
          </Route>
        )}
      </Switch>
    )

    const tabs = [
      <Tab
        icon={<HomeIcon />}
        label={t`Home`}
        component={Link}
        to="/"
        style={
          window.location.pathname === "/"
            ? { color: mainColor }
            : {
                color: tinyColor(textOnMainBackgroundColor).setAlpha(0.54),
              }
        }
      />,
      <Tab
        icon={<DeveloperBoard />}
        label={t`Things`}
        component={Link}
        to="/things"
        style={
          window.location.pathname === "/things"
            ? { color: mainColor }
            : {
                color: tinyColor(textOnMainBackgroundColor).setAlpha(0.54),
              }
        }
      />,
      (!localStorage.getItem("server") ||
        localStorage.getItem("server").includes(".igloo.ooo")) && (
        <Tab
          icon={<AttachMoney />}
          label={t`Payments`}
          component={Link}
          to="/payments"
          style={
            window.location.pathname === "/payments"
              ? { color: mainColor }
              : {
                  color: tinyColor(textOnMainBackgroundColor).setAlpha(0.54),
                }
          }
        />
      ),
    ]

    return (
      <Igloo
        bearer={bearer}
        server={
          localStorage.getItem("server")
            ? (localStorage.getItem("serverUnsecure") === "true"
                ? "http://"
                : "https://") + localStorage.getItem("server")
            : "https://v1.igloo.ooo"
        }
      >
        <Metadata fields={["privacyPolicyVersion"]}>
          {(metadataProps) => (
            <User
              fields={[
                "name",
                "email",
                "profileIconColor",
                "emailIsVerified",
                "usedThings",
                "maxThings",
                "usedThroughput",
                "maxThroughput",
                "usedStorage",
                "maxStorage",
                "usedDiskSpace",
                "maxDiskSpace",
                "timeFormat",
                "dateFormat",
                "lengthAndMass",
                "temperature",
                "passwordChangeEmail",
                "accessTokenCreatedEmail",
                "billingCycle",
                "billingPlan",
                "billingStatus",
                "nextBillingDate",
                "cardExpiryYear",
                "cardExpiryMonth",
                "cardLast4Digits",
                "developerThingCount",
                "paymentIntentSecret",
                "vatNumber",
                "addressLine1",
                "addressLine2",
                "addressCity",
                "addressPostalCode",
                "addressState",
                "addressCountryOrTerritory",
                "companyName",
                "vatRate",
                "language",
                "privacyPolicyAccepted",
                "totpEnabled",
                "businessPricing{id, price}",
                "flags{id, smsActive}",
              ]}
              onDelete={() => logOut(true)}
              privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
            >
              <PolicyDialogWrapper metadata={metadataProps.data} />
              {(userProps) => {
                if (
                  userProps.error?.message ===
                  "GraphQL error: This user doesn't exist anymore"
                ) {
                  logOut(true)
                }

                return (
                  <User
                    fields={["uniqueDeveloperFirmwares"]}
                    onDelete={() => logOut(true)}
                    privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                  >
                    {(firmwareProps) => (
                      <DeveloperThings
                        fields={[
                          "type",
                          "token",
                          "firmware",
                          "createdAt",
                          "updatedAt",
                          "owner{email}",
                          "pairCode",
                          "notificationCount",
                          "online",
                        ]}
                        limit={20}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        onDelete={(id) => {
                          if (query.get("id") === id) {
                            setRedirect("/things")
                          }
                        }}
                      >
                        {(developerThingsProps) => {
                          const selectedThing = developerThingsProps.data?.find(
                            (thing) => thing.id === query.get("id")
                          )

                          return (
                            <>
                              <Helmet>
                                <title>
                                  {appName +
                                    (window.location.pathname === "/things"
                                      ? developerThingsProps.data &&
                                        query.get("id") &&
                                        selectedThing
                                        ? " - " + selectedThing.type
                                        : " - " + t`Things`
                                      : window.location.pathname === "/payments"
                                      ? " - " + t`Payments`
                                      : "")}
                                </title>
                              </Helmet>
                              {mobile ? (
                                query.get("id") ? (
                                  <ThingBody
                                    mobile
                                    id={query.get("id")}
                                    logOut={logOut}
                                  />
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <Tabs
                                        variant="fullWidth"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        TabIndicatorProps={{
                                          style: {
                                            width: `${100 / tabs.length}%`,
                                            left: `${
                                              ((window.location.pathname === "/"
                                                ? 0
                                                : window.location.pathname ===
                                                  "/things"
                                                ? 1
                                                : 2) *
                                                100) /
                                              tabs.length
                                            }%`,
                                          },
                                        }}
                                      >
                                        {tabs}
                                      </Tabs>
                                    </Box>
                                    <SwipeableRoutes
                                      style={{
                                        height: mobile
                                          ? "calc(100% - 136px - env(safe-area-inset-bottom))"
                                          : "calc(100% - 136px)",
                                      }}
                                      containerStyle={{ height: "100%" }}
                                      disabled
                                    >
                                      <Route exact path="/">
                                        <Home mobile userProps={userProps} />
                                      </Route>
                                      <Route exact path="/things">
                                        <ThingMain
                                          mobile
                                          userProps={userProps}
                                          firmwareProps={firmwareProps}
                                          developerThingsProps={
                                            developerThingsProps
                                          }
                                          logOut={logOut}
                                          sortBy={sortBy}
                                          setSortBy={setSortBy}
                                          sortDirection={sortDirection}
                                          setSortDirection={setSortDirection}
                                        />
                                      </Route>
                                      {(!localStorage.getItem("server") ||
                                        localStorage
                                          .getItem("server")
                                          .includes(".igloo.ooo")) && (
                                        <Route exact path="/payments">
                                          <Payments
                                            mobile
                                            userProps={userProps}
                                          />
                                        </Route>
                                      )}
                                    </SwipeableRoutes>
                                    <AppBar
                                      position="fixed"
                                      color="primary"
                                      style={{
                                        height:
                                          "calc(64px + env(safe-area-inset-bottom))",
                                        boxShadow:
                                          "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
                                        top: "auto",
                                        bottom: 0,
                                        paddingBottom:
                                          "env(safe-area-inset-bottom)",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <img
                                          src={logo}
                                          style={{
                                            width: "56px",
                                            marginLeft: "16px",
                                          }}
                                          alt="Igloo"
                                          className="notSelectable"
                                        />
                                        <AccountPopoverWrapper
                                          openSettings={() =>
                                            setSettingsOpen(true)
                                          }
                                          user={userProps.data}
                                          setRedirect={setRedirect}
                                          logOut={logOut}
                                          changeAccount={changeAccount}
                                          isOpen={isOpen}
                                          forceUpdate={forceUpdate}
                                        />
                                      </div>
                                    </AppBar>
                                  </>
                                )
                              ) : greaterThan1200 ? (
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      zIndex: 10,
                                      height: "100%",
                                      width: "25%",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    <Sidebar
                                      greaterThan1200
                                      openSettings={() => setSettingsOpen(true)}
                                      settingsOpen={settingsOpen}
                                      userProps={userProps}
                                      logOut={logOut}
                                      changeAccount={changeAccount}
                                      isOpen={isOpen}
                                      forceUpdate={forceUpdate}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      height: "100%",
                                      overflowY: "auto",
                                      width: greaterThan1600
                                        ? "calc(100% - 400px)"
                                        : "75%",
                                    }}
                                  >
                                    {desktopRoutes(
                                      userProps,
                                      developerThingsProps,
                                      firmwareProps,
                                      true
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <Grid container style={{ height: "100%" }}>
                                  <Grid
                                    item
                                    style={{
                                      width: "64px",
                                      zIndex: 10,
                                      height: "100%",
                                    }}
                                  >
                                    <Sidebar
                                      openSettings={() => setSettingsOpen(true)}
                                      settingsOpen={settingsOpen}
                                      userProps={userProps}
                                      logOut={logOut}
                                      changeAccount={changeAccount}
                                      isOpen={isOpen}
                                      forceUpdate={forceUpdate}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      width: "calc(100% - 64px)",
                                      height: "100%",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {desktopRoutes(
                                      userProps,
                                      developerThingsProps,
                                      firmwareProps,
                                      false
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                              <EmailNotVerified
                                open={
                                  userProps.data &&
                                  !userProps.data.emailIsVerified &&
                                  snackbarOpen
                                }
                                close={() => setSnackbarOpen(false)}
                                user={userProps.data}
                                developerThings={developerThingsProps.data}
                                isPortal
                              />
                              <SettingsDialog
                                isOpen={settingsOpen}
                                logOut={logOut}
                                user={
                                  !developerThingsProps.error && userProps.data
                                }
                                setOpen={setSettingsOpen}
                                forceUpdate={forceUpdate}
                                isPortal
                              />
                            </>
                          )
                        }}
                      </DeveloperThings>
                    )}
                  </User>
                )
              }}
            </User>
          )}
        </Metadata>
      </Igloo>
    )
  }
)
