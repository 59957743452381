import React, { PureComponent } from "react"
import { withStyles } from "@mui/styles"

const styles = () => ({
  root: {
    width: "100%",
    padding: "6px 0 7px",
    cursor: "text",
  },
})

export default withStyles(styles, { withTheme: true })(
  class extends PureComponent {
    static displayName = "StripeInput"

    render() {
      const {
        classes: c,
        theme,
        component: Component,
        onFocus,
        onBlur,
        handleChange,
      } = this.props

      return (
        <Component
          className={c.root}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder=""
          style={{
            base: {
              fontSize: "16px",
              fontFamily: theme.typography.fontFamily,
              color: "#000000",
            },
          }}
        />
      )
    }
  }
)
